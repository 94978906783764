@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700);
[data-type='@e.toggle'] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: inherit;
  cursor: pointer; }
  [data-type='@e.toggle'] [data-part='title'] {
    line-height: 1em; }
  [data-type='@e.toggle'] [data-part='switch'] {
    display: flex;
    border-radius: 0.8em;
    position: relative;
    background-color: #555;
    width: 1.4em;
    height: 0.6em;
    cursor: pointer;
    margin: 0 0.7em; }
    [data-type='@e.toggle'] [data-part='switch'] [data-part='indicator'] {
      width: 1em;
      height: 1em;
      display: block;
      position: absolute;
      top: -0.2em;
      left: -0.2em;
      -webkit-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out;
      border-radius: 50%;
      background: #999; }
  [data-type='@e.toggle'] > * {
    margin: 0 0.5em; }
  [data-type='@e.toggle'][data-active="true"] > [data-part='switch'] > [data-part='indicator'] {
    left: 50%; }
  [data-type='@e.toggle'][data-active="false"] {
    color: #888; }
    [data-type='@e.toggle'][data-active="false"] [data-part='switch'] {
      background-color: #888; }
      [data-type='@e.toggle'][data-active="false"] [data-part='switch'] [data-part='indicator'] {
        background: #626262; }
  [data-type='@e.toggle'][data-active="true"] {
    color: #e6e6e6; }
    [data-type='@e.toggle'][data-active="true"] [data-part='switch'] {
      background-color: white; }
      [data-type='@e.toggle'][data-active="true"] [data-part='switch'] [data-part='indicator'] {
        background: #ccc; }
  [data-type='@e.toggle'] [data-part='switch'] {
    border-radius: 0.5em;
    width: 1.4em;
    height: 0.6em; }
    [data-type='@e.toggle'] [data-part='switch'] [data-part='indicator'] {
      width: 1em;
      height: 1em;
      top: -0.2em;
      left: -0.2em; }
  [data-type='@e.toggle'][data-active="true"] > [data-part='switch'] > [data-part='indicator'] {
    left: 50%; }
  [data-type='@e.toggle'].-multi [data-part='multiswitch'] {
    display: flex;
    border-radius: 0.8em;
    position: relative;
    cursor: pointer;
    display: flex; }
    [data-type='@e.toggle'].-multi [data-part='multiswitch'] [data-part='multiswitch-item'] {
      width: 1.6rem;
      height: 1.6rem;
      display: inline-block;
      z-index: 1; }
      [data-type='@e.toggle'].-multi [data-part='multiswitch'] [data-part='multiswitch-item'] [data-type="@e.icon"] {
        width: 1.2rem !important;
        height: 1.2rem !important;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      [data-type='@e.toggle'].-multi [data-part='multiswitch'] [data-part='multiswitch-item'] [data-active="true"] {
        opacity: 1; }
    [data-type='@e.toggle'].-multi [data-part='multiswitch'] [data-part='indicator'] {
      width: 1.6rem;
      height: 1.6rem;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out;
      border-radius: 50%;
      background-color: white;
      z-index: 0; }
    [data-type='@e.toggle'].-multi [data-part='multiswitch']:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 0.4em);
      height: calc(100% + 0.4em);
      background-color: #555;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      border-radius: 0.8em; }

[data-type='@e.nav'] {
  font-size: 1em;
  background: #444; }
  [data-type='@e.nav'] > * {
    display: block; }
  [data-type='@e.nav'] * {
    font-size: inherit; }
  [data-type='@e.nav'] [data-part="header"] {
    display: flex;
    align-items: center;
    background: lightgrey; }
  [data-type='@e.nav'] > [data-part="section"] > [data-part='title'] {
    position: relative;
    display: block;
    font-size: 1rem;
    text-transform: uppercase;
    color: grey; }
  [data-type='@e.nav'] > [data-part="section"] > [data-part="item"] {
    display: block; }
    [data-type='@e.nav'] > [data-part="section"] > [data-part="item"] > [data-part="button"] {
      display: flex;
      align-item: center;
      width: 100%;
      line-height: 1em; }
      [data-type='@e.nav'] > [data-part="section"] > [data-part="item"] > [data-part="button"] > * {
        margin-right: 0.5em; }
  [data-type='@e.nav'] > [data-part="section"] [data-part='controls'] {
    position: relative;
    display: block;
    font-size: 1rem; }
  [data-type='@e.nav'] > [data-part="section"]:not(:last-child) {
    border-bottom: 1px solid lightgrey; }
  [data-type='@e.nav'] [data-part="header"] {
    margin-bottom: 1em;
    padding: 0.5em 2em; }
  [data-type='@e.nav'] [data-part="section"] {
    padding: 1em 1em; }
    [data-type='@e.nav'] [data-part="section"] > [data-part='title'] {
      padding: 0 0.5em;
      margin-bottom: 0.8em; }
    [data-type='@e.nav'] [data-part="section"] [data-part="item"] {
      padding: 0.5em; }
    [data-type='@e.nav'] [data-part="section"] [data-part='controls'] {
      padding: 0 0.5em;
      margin-top: 0.8em; }
    [data-type='@e.nav'] [data-part="section"]:not(:last-child) {
      padding-bottom: 1em; }
  [data-type='@e.nav'] [data-part="header"] {
    background: #515151; }
  [data-type='@e.nav'] [data-part="section"] > [data-part='title'] {
    color: rgba(187, 187, 187, 0.6); }
  [data-type='@e.nav'] [data-part="section"]:not(:last-child) {
    border-bottom-color: rgba(187, 187, 187, 0.1); }

[data-type='@e.icon'] {
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.15s;
  transition: all 0.15s; }
  [data-type='@e.icon'] > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    [data-type='@e.icon'] > svg *:not([fill="none"]) {
      fill: currentColor; }
  [data-type='@e.icon'][data-clickable="true"] {
    cursor: pointer; }

/**********

	COLOURS : EVS V3

**********/
.\@e.-layout.--section {
  display: flex;
  justify-content: left;
  align-items: stretch;
  margin-bottom: 1rem;
  max-height: 100vh;
  -webkit-transition: max-height 0.2s;
  transition: max-height 0.2s;
  position: relative; }
  .\@e.-layout.--section ._resize {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.5em; }
    .\@e.-layout.--section ._resize span {
      width: 1.4rem;
      height: 1.4rem;
      margin-left: 0.4em;
      position: relative;
      cursor: pointer;
      background: #606262;
      -webkit-transition: background 0.15s;
      transition: background 0.15s; }
      .\@e.-layout.--section ._resize span._small {
        height: 0.3em; }
      .\@e.-layout.--section ._resize span._medium {
        height: 0.7rem; }
      .\@e.-layout.--section ._resize span._large {
        height: 1.4rem; }
      .\@e.-layout.--section ._resize span:hover {
        background: #95989A; }
      .\@e.-layout.--section ._resize span[data-selected="true"] {
        background: #95989A; }
      .\@e.-layout.--section ._resize span:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1.4rem;
        height: 1.4rem;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease; }
  .\@e.-layout.--section > article {
    width: 100%;
    z-index: 1; }
  .\@e.-layout.--section[data-size="small"] {
    max-height: 40rem; }
  .\@e.-layout.--section[data-size="medium"] {
    max-height: 50rem; }
  .\@e.-layout.--section[data-size="large"] {
    max-height: 70rem; }
  .\@e.-layout.--section[data-size] {
    height: 100vh; }

.\@e.-layout.--group {
  display: flex;
  justify-content: left;
  margin-bottom: 2rem; }
  .\@e.-layout.--group .--panel ._inner {
    margin: 0 0.5em; }
  .\@e.-layout.--group .--panel:first-child ._inner {
    margin-left: 0; }
  .\@e.-layout.--group .--panel:last-child ._inner {
    margin-right: 0; }
  .\@e.-layout.--group .--panel + .--panel {
    margin-top: 0; }

.\@e.-layout.--panel {
  display: flex;
  justify-content: left;
  align-items: stretch;
  width: 100%; }
  .\@e.-layout.--panel ._inner {
    display: block;
    width: 100%;
    padding: 1.5em 2em;
    background: #FFFFFF;
    box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.03); }
    .\@e.-layout.--panel ._inner > ._title {
      font-size: 1.2em;
      color: #0B1319;
      margin: 0;
      margin-bottom: 1.5em; }
  .\@e.-layout.--panel + .--panel {
    margin-top: 1em; }
  .\@e.-layout.--panel[data-borderless="true"] ._inner {
    padding: 0; }

[data-type='@e.link'] {
  display: flex;
  align-items: center;
  line-height: 1em; }
  [data-type='@e.link'] > * {
    margin: 0 0.25em;
    -webkit-transition: color 0.15s;
    transition: color 0.15s; }
    [data-type='@e.link'] > *:first-child {
      margin-left: 0.5em; }
    [data-type='@e.link'] > *:last-child {
      margin-right: 0.5em; }

span[data-type='@e.badge'] {
  display: inline-block;
  height: 1em;
  width: auto;
  z-index: 2;
  position: relative;
  color: white;
  font-style: normal; }
  span[data-type='@e.badge'].--circle {
    width: 1.2em;
    height: 1.2em;
    font-size: 1em;
    background: #c8c8c8;
    border-radius: 50%; }
  span[data-type='@e.badge'] > span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    line-height: 1em; }

.\@e.-timeline {
  display: flex;
  height: 2em;
  position: relative; }
  .\@e.-timeline:before, .\@e.-timeline:after {
    content: '';
    position: absolute;
    top: 0;
    height: 1em;
    width: 1px;
    background: rgba(0, 0, 0, 0.2); }
  .\@e.-timeline:before {
    left: 0; }
  .\@e.-timeline:after {
    right: 0; }

.\@e.-clock {
  display: block;
  color: inherit;
  background: inherit;
  text-align: center; }
  .\@e.-clock > * {
    display: block;
    white-space: nowrap; }
  .\@e.-clock ._date {
    font-size: 1.4rem; }
  .\@e.-clock ._time {
    font-size: 1rem; }

[data-type="@e.dot"] {
  display: inline-block;
  position: relative;
  color: white;
  border-radius: 50%;
  font-size: 0.8em;
  width: 1.2em;
  height: 1.2em;
  background: grey;
  color: white; }
  [data-type="@e.dot"] > span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    line-height: 1em; }
  [data-type="@e.dot"][data-clickable="true"] {
    cursor: pointer; }

.\@e.-pill {
  position: relative;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 2em;
  padding: 0.25em 0.66667em;
  line-height: 1em;
  font-size: 1em;
  color: white;
  background-color: #999;
  border: 1px solid white; }
  .\@e.-pill > * {
    margin: 0 0.33333em; }
  .\@e.-pill[data-active="true"] {
    color: #4d4d4d;
    border-color: #4d4d4d; }
  .\@e.-pill[data-clickable="true"] {
    cursor: pointer; }

[data-type="@e.button"] {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1.4em; }
  [data-type="@e.button"] > * {
    margin: 0 0.4em; }

[data-type="@e.button-group"] {
  display: flex;
  align-items: center;
  justify-content: center; }
  [data-type="@e.button-group"] .\@e.-button {
    position: relative; }
    [data-type="@e.button-group"] .\@e.-button:not(:last-child):after {
      content: '';
      position: absolute;
      top: 20%;
      left: 100%;
      width: 1px;
      height: 60%;
      background: #ddd;
      z-index: 1; }

.\@e.-inlineconfirm {
  font-size: 1em;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: black; }
  .\@e.-inlineconfirm ._title,
  .\@e.-inlineconfirm ._confirm {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear; }
  .\@e.-inlineconfirm ._title {
    cursor: pointer;
    max-width: 10rem;
    opacity: 0.5; }
    .\@e.-inlineconfirm ._title:hover {
      opacity: 1; }
  .\@e.-inlineconfirm ._confirm {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
    max-width: 0; }
    .\@e.-inlineconfirm ._confirm .-option {
      cursor: pointer;
      margin: 0 0.2em; }
      .\@e.-inlineconfirm ._confirm .-option.--yes {
        color: green; }
      .\@e.-inlineconfirm ._confirm .-option.--no {
        color: red; }
  .\@e.-inlineconfirm[data-open="true"] ._title {
    opacity: 0;
    max-width: 0; }
  .\@e.-inlineconfirm[data-open="true"] ._confirm {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    max-width: 10rem; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

[data-type='@e.contextmenu'] {
  position: relative;
  z-index: 1000;
  display: inline-block; }
  [data-type='@e.contextmenu'] [data-part='trigger'] {
    display: block;
    z-index: 2;
    position: relative;
    cursor: pointer;
    white-space: nowrap; }
    [data-type='@e.contextmenu'] [data-part='trigger'] > * {
      display: flex; }
  [data-type='@e.contextmenu'] [data-part='options'] {
    position: absolute;
    height: auto;
    overflow: visible;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 1;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 15em; }
    [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] {
      padding: 1.6rem;
      background: white;
      color: black;
      max-height: 50rem;
      height: auto;
      width: 100%; }
      [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] > * {
        white-space: nowrap;
        display: block; }
      [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] [data-part='options-group'] {
        margin-bottom: 0.8em;
        padding-bottom: 0.8em; }
        [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] [data-part='options-group'] > [data-part='title'] {
          font-size: 0.75em;
          text-transform: uppercase;
          color: #555;
          margin-bottom: 0.6em; }
        [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] [data-part='options-group']:last-child {
          margin-bottom: 0;
          padding-bottom: 0; }
    [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] {
      height: 0.6em; }
      [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
        width: 1.2em;
        height: 0.6em;
        position: absolute;
        fill: white; }
  [data-type='@e.contextmenu'] [data-part='item'] {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.4em 0; }
    [data-type='@e.contextmenu'] [data-part='item'] > * {
      text-align: left;
      margin-right: 0.5em; }
      [data-type='@e.contextmenu'] [data-part='item'] > *:last-child {
        margin-right: 0; }
    [data-type='@e.contextmenu'] [data-part='item'] [data-part='item-group'] {
      display: flex;
      align-items: center;
      padding: 0 0.4em;
      width: auto; }
      [data-type='@e.contextmenu'] [data-part='item'] [data-part='item-group']:first-child {
        padding-left: 0; }
      [data-type='@e.contextmenu'] [data-part='item'] [data-part='item-group']:last-child {
        padding-right: 0; }
  [data-type='@e.contextmenu'] [data-part='cta'] {
    color: blue;
    text-align: left;
    margin-top: 1em;
    font-size: 0.75em;
    text-transform: uppercase; }
  [data-type='@e.contextmenu'][data-open="false"] [data-part='options'] {
    width: 0;
    height: 0;
    pointer-events: none;
    max-width: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden; }
    [data-type='@e.contextmenu'][data-open="false"] [data-part='options'] [data-part='children'] {
      padding: 0 1.6em; }
  [data-type='@e.contextmenu'] [data-part='options'] {
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
    -webkit-transform: none;
            transform: none;
    flex-direction: column-reverse;
    bottom: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] {
      height: auto;
      width: auto; }
      [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
        right: unset;
        left: unset;
        -webkit-transform: none;
                transform: none; }
    [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] {
      height: 0.6em; }
    [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
  [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
    fill: #fff; }
  [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] {
    background: #fff;
    color: black; }
    [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] [data-part='options-group'] > [data-part='title'] {
      color: rgba(0, 0, 0, 0.7); }
  [data-type='@e.contextmenu'] [data-part='options'] [data-part='options-group']:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

[data-type="@e.panel"] {
  background: #eee;
  color: #111111; }
  [data-type="@e.panel"] > [data-part="header"] {
    display: flex;
    align-items: stretch;
    height: 4em;
    z-index: 1;
    position: relative; }
    [data-type="@e.panel"] > [data-part="header"] > * {
      display: flex;
      align-items: center; }
    [data-type="@e.panel"] > [data-part="header"] [data-part="divider"] {
      width: 1px;
      height: 2rem;
      display: inline-block;
      margin: 0 1em;
      background: rgba(0, 0, 0, 0.1); }
  [data-type="@e.panel"] > [data-part="body"] {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    max-height: 100vh;
    padding: 1rem 1.5rem;
    z-index: 2;
    position: relative; }
    [data-type="@e.panel"] > [data-part="body"] > * {
      opacity: 1;
      -webkit-transition: opacity 0.2s ease-in-out;
      transition: opacity 0.2s ease-in-out; }
  [data-type="@e.panel"][data-open="true"] > [data-part="header"] {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  [data-type="@e.panel"][data-open="false"] > [data-part="body"] {
    max-height: 0;
    padding: 0;
    overflow: hidden; }
    [data-type="@e.panel"][data-open="false"] > [data-part="body"] > * {
      opacity: 0; }
  [data-type="@e.panel"] [data-part="divider"] {
    background: rgba(17, 17, 17, 0.25); }

[data-type="@e.date"] {
  display: flex;
  align-items: center; }
  [data-type="@e.date"] [data-part="value"] [data-part="part"] {
    margin-right: 0.3em; }
  [data-type="@e.date"] > *:not(:last-child) {
    margin-right: 0.3em; }

[data-type='@e.headerbar'] {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; }
  [data-type='@e.headerbar'] [data-part='group'] {
    margin: 0;
    display: flex;
    justify-content: center;
    width: 33.3%; }
    [data-type='@e.headerbar'] [data-part='group']:first-child {
      justify-content: flex-start; }
    [data-type='@e.headerbar'] [data-part='group']:last-child {
      justify-content: flex-end; }

[data-type='@e.breadcrumbs'] {
  color: inherit;
  display: flex;
  align-items: center; }
  [data-type='@e.breadcrumbs'] [data-part="crumb"] {
    margin-right: 0.3em;
    display: flex; }
    [data-type='@e.breadcrumbs'] [data-part="crumb"]:not(:first-child):before {
      content: '/\00a0\00a0'; }

[data-type='@e.select'] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: inherit; }
  [data-type='@e.select'] [data-part='title'] {
    margin: 0 1em 0 0; }

svg[data-type='@e.horseshoe-progress'] [data-part="progress"] {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

[data-type="@e.hint"] {
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
  display: inline-block;
  text-align: left; }
  [data-type="@e.hint"] [data-type="@e.icon"] {
    display: block; }
  [data-type="@e.hint"] [data-part="popup"] {
    position: absolute;
    bottom: calc(100% + 0.7rem);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    max-width: 30em;
    min-width: 10em;
    word-wrap: break-word;
    padding: 0.6em 1em;
    pointer-events: none;
    line-height: 1.4em;
    font-weight: 300;
    letter-spacing: 0em;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-align: left; }
    [data-type="@e.hint"] [data-part="popup"]:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      border-left: 0.4em solid transparent;
      border-right: 0.4em solid transparent;
      border-top: 0.4em solid transparent; }
    [data-type="@e.hint"] [data-part="popup"] [data-part="popup-title"] {
      display: block;
      font-weight: 500;
      white-space: nowrap;
      margin-bottom: 0.3em; }
  [data-type="@e.hint"]:hover {
    overflow: visible; }
    [data-type="@e.hint"]:hover [data-part="popup"] {
      opacity: 1;
      overflow: visible; }
  [data-type="@e.hint"] [data-part="popup"] {
    background: #eee;
    color: #333; }
    [data-type="@e.hint"] [data-part="popup"]:before {
      border-top-color: #eee; }
    [data-type="@e.hint"] [data-part="popup"] [data-part="popup-title"] {
      color: #111; }

/**********

	COLOURS : EVS V3

**********/
.\@l.-dashboard {
  padding: 2rem; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/**********

	COLOURS : EVS V3

**********/
[data-type='@c.weatherwidget.static'],
[data-type='@c.weatherwidget'] {
  display: flex;
  align-items: center; }

[data-type='@c.weatherwidget'] [data-part='conditions'] {
  display: flex;
  align-items: center;
  margin-right: 0.5em; }
  [data-type='@c.weatherwidget'] [data-part='conditions'] [data-part='conditions-group'] {
    margin: 0 0.2em;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    line-height: 1em; }
    [data-type='@c.weatherwidget'] [data-part='conditions'] [data-part='conditions-group'] [data-part='value'] {
      margin: 0 0.2em;
      font-size: 1.2rem; }
    [data-type='@c.weatherwidget'] [data-part='conditions'] [data-part='conditions-group'][data-type="temperature"] {
      font-size: 1.6rem; }
      [data-type='@c.weatherwidget'] [data-part='conditions'] [data-part='conditions-group'][data-type="temperature"] [data-part='value'] {
        margin: 0 0.5em;
        font-size: 1.4rem; }

[data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] {
  left: unset;
  right: unset;
  top: unset;
  bottom: unset;
  -webkit-transform: none;
          transform: none;
  flex-direction: column-reverse;
  bottom: 100%;
  right: calc(100% - 2em); }
  [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] {
    height: auto;
    width: auto; }
    [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
      right: unset;
      left: unset;
      -webkit-transform: none;
              transform: none; }
  [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] {
    height: 0.6em; }
  [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
    right: 0.5em; }

[data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
  fill: black; }

[data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] {
  background: black;
  color: white; }
  [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] [data-part='options-group'] > [data-part='title'] {
    color: rgba(255, 255, 255, 0.7); }

[data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='options-group']:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

[data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-type='@e.icon'][data-variation="cog"]:not(:hover) {
  opacity: 0.4; }

[data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='item'] {
  justify-content: space-between; }
  [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='item'] [data-part='item-group']:first-child {
    min-width: 10rem; }
  [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='item'] [data-part='item-group']:last-child {
    padding-right: 0; }
  [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-part='item'] [data-type='@e.icon'] {
    margin-right: 0.3em;
    width: 0.9em;
    height: 0.9em; }

[data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-type='@e.toggle'][data-active="true"][data-active="false"] {
  color: #555; }
  [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-type='@e.toggle'][data-active="true"][data-active="false"] [data-part='switch'] {
    background-color: #555; }
    [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-type='@e.toggle'][data-active="true"][data-active="false"] [data-part='switch'] [data-part='indicator'] {
      background: #2f2f2f; }

[data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-type='@e.toggle'][data-active="true"][data-active="true"] {
  color: #6bbce6; }
  [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-type='@e.toggle'][data-active="true"][data-active="true"] [data-part='switch'] {
    background-color: #c2e3f5; }
    [data-type='@c.weatherwidget'] [data-type='@e.contextmenu'] [data-part='options'] [data-type='@e.toggle'][data-active="true"][data-active="true"] [data-part='switch'] [data-part='indicator'] {
      background: #40A8DE; }

/**********

	COLOURS : EVS V3

**********/
[data-type='@c.weatherforecast'] ._weather-chart {
  display: table;
  border-collapse: collapse;
  width: 100%;
  position: relative; }
  [data-type='@c.weatherforecast'] ._weather-chart ._row {
    display: table-row;
    position: relative;
    border: 1px solid rgba(149, 152, 154, 0.3); }
    [data-type='@c.weatherforecast'] ._weather-chart ._row > * {
      text-align: center;
      display: table-cell;
      padding: 0.5em 0.1em; }
      [data-type='@c.weatherforecast'] ._weather-chart ._row > *:nth-child(8n + 2), [data-type='@c.weatherforecast'] ._weather-chart ._row > *:nth-child(8n + 3), [data-type='@c.weatherforecast'] ._weather-chart ._row > *:nth-child(8n + 4), [data-type='@c.weatherforecast'] ._weather-chart ._row > *:nth-child(8n + 5) {
        background: rgba(228, 231, 232, 0.1); }
    [data-type='@c.weatherforecast'] ._weather-chart ._row ._row-name {
      text-align: right;
      font-weight: 400;
      padding: 0.5em; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/**********

	COLOURS : EVS V3

**********/
[data-type='@c.weatherforecast'] {
  display: flex;
  align-items: center; }

/**********

	COLOURS : EVS V3

**********/
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

[data-part="map-controls"] [data-type="@e.nav"] {
  font-size: 1.1rem;
  background: #0E1D26; }
  [data-part="map-controls"] [data-type="@e.nav"] [data-part="header"] {
    margin-bottom: 1.1rem;
    padding: 0.55rem 2.2rem; }
  [data-part="map-controls"] [data-type="@e.nav"] [data-part="section"] {
    padding: 1.1rem 1.1rem; }
    [data-part="map-controls"] [data-type="@e.nav"] [data-part="section"] > [data-part='title'] {
      padding: 0 0.55rem;
      margin-bottom: 0.88rem; }
    [data-part="map-controls"] [data-type="@e.nav"] [data-part="section"] [data-part="item"] {
      padding: 0.55rem; }
    [data-part="map-controls"] [data-type="@e.nav"] [data-part="section"] [data-part='controls'] {
      padding: 0 0.55rem;
      margin-top: 0.88rem; }
    [data-part="map-controls"] [data-type="@e.nav"] [data-part="section"]:not(:last-child) {
      padding-bottom: 1.1rem; }
  [data-part="map-controls"] [data-type="@e.nav"] [data-part="header"] {
    background: #152b39; }
  [data-part="map-controls"] [data-type="@e.nav"] [data-part="section"] > [data-part='title'] {
    color: rgba(241, 226, 217, 0.6); }
  [data-part="map-controls"] [data-type="@e.nav"] [data-part="section"]:not(:last-child) {
    border-bottom-color: rgba(241, 226, 217, 0.1); }
  [data-part="map-controls"] [data-type="@e.nav"] [data-part="header"] {
    padding-bottom: 0;
    padding-top: 1em; }
    [data-part="map-controls"] [data-type="@e.nav"] [data-part="header"] [data-type="@e.button"] {
      padding: 0.5em 0.5em;
      margin: 0 0.5em;
      opacity: 0.5;
      position: relative; }
      [data-part="map-controls"] [data-type="@e.nav"] [data-part="header"] [data-type="@e.button"]:first-child {
        margin-left: 0; }
      [data-part="map-controls"] [data-type="@e.nav"] [data-part="header"] [data-type="@e.button"]:last-child {
        margin-right: 0; }
      [data-part="map-controls"] [data-type="@e.nav"] [data-part="header"] [data-type="@e.button"]:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        height: 1px;
        width: 0;
        background: currentColor;
        -webkit-transition: all 0.2s;
        transition: all 0.2s; }
      [data-part="map-controls"] [data-type="@e.nav"] [data-part="header"] [data-type="@e.button"][data-active="true"] {
        opacity: 1; }
        [data-part="map-controls"] [data-type="@e.nav"] [data-part="header"] [data-type="@e.button"][data-active="true"]:after {
          width: 100%; }
      [data-part="map-controls"] [data-type="@e.nav"] [data-part="header"] [data-type="@e.button"] [data-type="@e.icon"] {
        width: 1.6em;
        height: 1.6em; }
  [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] > [data-part="item"] {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
  [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] .particulate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    opacity: 1; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] .particulate > span {
      display: flex;
      align-items: center; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] .particulate [data-type="@e.dot"] {
      width: 0.6em;
      height: 0.6em; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] .particulate [data-part="name"] {
      margin-left: 0.5em; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] .particulate [data-part="count"] {
      margin-right: 0.5em; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] .particulate.-inactive {
      opacity: 0.5; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] .particulate.-inactive [data-type="@e.icon"] {
        opacity: 0.5; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] .particulate.-inactive:hover {
        opacity: 0.8; }
  [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"][data-name="options"] > [data-part="item"] {
    min-width: 16rem;
    padding: 0.5em 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"][data-name="options"] > [data-part="item"] > * {
      width: 100%; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"][data-name="options"] > [data-part="item"] [data-type="@e.icon"] {
      width: 1.4em;
      height: 1.4em; }
  [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] > [data-part="item"][data-visible="false"] {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0; }
  [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.button"] {
    padding: 0; }
  [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"] {
    justify-content: flex-start; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"][data-active="false"] {
      color: #fff; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"][data-active="false"] [data-part='switch'] {
        background-color: #fff; }
        [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"][data-active="false"] [data-part='switch'] [data-part='indicator'] {
          background: #d9d9d9; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"][data-active="true"] {
      color: #6bbce6; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"][data-active="true"] [data-part='switch'] {
        background-color: #c2e3f5; }
        [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"][data-active="true"] [data-part='switch'] [data-part='indicator'] {
          background: #40A8DE; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"] [data-part='title'] {
      display: flex;
      align-items: center; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"] [data-type="@e.hint"] {
      margin: 0; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"] [data-type="@e.hint"] [data-type="@e.icon"] {
        width: 1em;
        height: 1em; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"] [data-part="switch"] {
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translate(50%, -50%);
              transform: translate(50%, -50%); }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"][data-active="false"] [data-part="title"],
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"][data-active="false"] [data-type="@e.icon"],
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"][data-active="false"] [data-part="switch"],
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"][data-active="false"] [data-part="multiswitch"] {
      opacity: 0.4; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.toggle"].-multi [data-part="multiswitch"] {
      position: absolute;
      top: 50%;
      right: 0;
      margin: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
  [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] {
    display: block;
    width: 100%;
    opacity: 1; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part='options'] {
      left: unset;
      right: unset;
      top: unset;
      bottom: unset;
      -webkit-transform: none;
              transform: none;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      left: 100%;
      flex-direction: row; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part='options'] [data-part='pointer'] {
        height: auto;
        width: auto; }
        [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part='options'] [data-part='pointer'] svg {
          right: unset;
          left: unset;
          -webkit-transform: none;
                  transform: none; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part='options'] [data-part='pointer'] {
        width: 0.6em; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part='options'] [data-part='pointer'] svg {
        top: 50%;
        -webkit-transform: rotate(90deg) translate(-25%, 30%);
                transform: rotate(90deg) translate(-25%, 30%); }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part='options'] [data-part='pointer'] svg {
      fill: #070f13; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part='options'] [data-part='children'] {
      background: #070f13;
      color: #f8f0ec; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part='options'] [data-part='children'] [data-part='options-group'] > [data-part='title'] {
        color: rgba(248, 240, 236, 0.7); }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part='options'] [data-part='options-group']:not(:last-child) {
      border-bottom: 1px solid rgba(248, 240, 236, 0.2); }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part="trigger"] {
      padding-right: 1.2em; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part="trigger"]:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0.5em;
        width: 0.45em;
        height: 0.45em;
        -webkit-transform: translateY(-50%) rotateZ(-45deg);
                transform: translateY(-50%) rotateZ(-45deg);
        border-right: 1px solid white;
        border-bottom: 1px solid white;
        opacity: 0.5;
        -webkit-transition: all 0.2s;
        transition: all 0.2s; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part="trigger"]:hover:after {
        opacity: 1;
        right: 0.3em; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part="children"] {
      min-width: 19em; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part="children"] > * {
        width: 100%; }
      [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"] [data-part="children"] [data-type="@e.icon"] {
        margin-left: 0; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"][data-active="true"] [data-part="trigger"] {
      color: #40A8DE; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"][data-active="true"] [data-type="@e.icon"] {
      opacity: 1; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"].active-event [data-part="trigger"] {
      color: #fdc02f; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"].active-event [data-type="@e.dot"] {
      background: #fdc02f;
      color: white; }
    [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.contextmenu"].active-event .analyse-event {
      color: #fdc02f;
      text-align: right;
      display: block;
      width: 100%;
      margin-top: 1em; }
  [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-type="@e.dot"] {
    width: 1.6em;
    height: 1.6em;
    background: #40A8DE;
    color: white; }
  [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] .with-selected [data-type="@e.icon"] {
    margin-left: -0.5em;
    width: 1.6em;
    height: 1.6em; }
  [data-part="map-controls"] [data-type="@e.nav"] > [data-part="section"] [data-part='controls'] {
    color: #40A8DE;
    text-align: right; }

/**********

	COLOURS : EVS V3

**********/
[data-part="interface-container"] {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
  [data-part="interface-container"][data-closed="true"] {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  [data-part="interface-container"] [data-type="@e.panel"][data-open="true"] {
    box-shadow: 0 0 10em rgba(0, 0, 0, 0.3); }

/**********

	COLOURS : EVS V3

**********/
[data-part="map-nav"] {
  background: #0B1319;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6em 1em; }
  [data-part="map-nav"] [data-part="column"] {
    display: flex;
    align-items: center; }
    [data-part="map-nav"] [data-part="column"].left [data-type='@e.link'] {
      padding: 0.6em 0.5em;
      opacity: 0.5;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      position: relative; }
      [data-part="map-nav"] [data-part="column"].left [data-type='@e.link']:before {
        content: '';
        position: absolute;
        top: -0.8rem;
        left: 50%;
        width: 0;
        height: 0.2rem;
        background: #40A8DE;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        opacity: 0; }
      [data-part="map-nav"] [data-part="column"].left [data-type='@e.link'].--current, [data-part="map-nav"] [data-part="column"].left [data-type='@e.link'].--current:hover {
        opacity: 1; }
        [data-part="map-nav"] [data-part="column"].left [data-type='@e.link'].--current:before, [data-part="map-nav"] [data-part="column"].left [data-type='@e.link'].--current:hover:before {
          width: 100%;
          opacity: 1; }
      [data-part="map-nav"] [data-part="column"].left [data-type='@e.link']:hover {
        opacity: 1; }
    [data-part="map-nav"] [data-part="column"].left [data-part="section"] {
      display: flex;
      align-items: center;
      padding: 0 0.5em; }
      [data-part="map-nav"] [data-part="column"].left [data-part="section"]:first-child {
        padding-left: 0; }
      [data-part="map-nav"] [data-part="column"].left [data-part="section"]:last-child {
        padding-right: 0; }
      [data-part="map-nav"] [data-part="column"].left [data-part="section"]:not(:last-child) {
        border-right: 1px solid #0E1D26; }
    [data-part="map-nav"] [data-part="column"].right {
      justify-content: flex-end; }

/**********

	COLOURS : EVS V3

**********/
[data-part="map-date"] {
  font-size: 1.1rem;
  background: rgba(11, 19, 25, 0.8);
  padding: 0.3em 0.5em;
  border: 1px solid rgba(248, 248, 248, 0.5);
  border-radius: 0.4em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.7;
  line-height: 1em;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s; }
  [data-part="map-date"] > * {
    margin: 0 0.3em; }
  [data-part="map-date"] > span[data-part='title'],
  [data-part="map-date"] [data-type="@e.date"] .date {
    font-weight: 600; }
  [data-part="map-date"] > span[data-part='subtitle'],
  [data-part="map-date"] [data-type="@e.time"] .date {
    font-size: 0.9em;
    opacity: 0.7; }
  [data-part="map-date"] [data-type="@e.dot"] {
    width: 0.7em;
    height: 0.7em; }
  [data-part="map-date"][data-live="true"] [data-type="@e.dot"] {
    background: #71D891;
    color: white; }
  [data-part="map-date"]:hover {
    opacity: 1; }

/**********

	COLOURS : EVS V3

**********/
[data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] {
  justify-content: space-between;
  padding: 0 1.5rem; }
  [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] ._livestats > * {
    margin-right: 2em; }
    [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] ._livestats > *:first-child {
      font-weight: 600; }
  [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] ._weather {
    display: flex;
    align-items: center; }
    [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] ._weather > * {
      margin: 0 0.75em; }
  [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"][data-active="false"] {
    color: #95989A; }
    [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"][data-active="false"] [data-part='switch'] {
      background-color: #95989A; }
      [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"][data-active="false"] [data-part='switch'] [data-part='indicator'] {
        background: #6f7274; }
  [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"][data-active="true"] {
    color: #6bbce6; }
    [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"][data-active="true"] [data-part='switch'] {
      background-color: #c2e3f5; }
      [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"][data-active="true"] [data-part='switch'] [data-part='indicator'] {
        background: #40A8DE; }
  [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"] [data-part='switch'] {
    border-radius: 0.6em;
    width: 1.68em;
    height: 0.72em; }
    [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"] [data-part='switch'] [data-part='indicator'] {
      width: 1.2em;
      height: 1.2em;
      top: -0.24em;
      left: -0.24em; }
  [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"][data-active="true"] > [data-part='switch'] > [data-part='indicator'] {
    left: 50%; }
  [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"] [data-type="@e.icon"] {
    width: 1.3em;
    height: 1.3em; }
  [data-type='@l.map']
[data-type="@e.panel"].-monitoring [data-part="header"] [data-type="@e.toggle"] ._title {
    margin-right: 1.5em; }

/**********

	COLOURS : EVS V3

**********/
[data-type="@e.panel"].-comparison > [data-part="header"] {
  border-bottom: none;
  height: auto;
  min-height: 4em;
  align-items: center; }
  [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"] {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 1em 4em 1em 2.5em;
    position: relative;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
    [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"]:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 0;
      height: 0.2em;
      background: #40A8DE;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      opacity: 0; }
    [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"][data-active="true"]:after {
      width: 100%;
      opacity: 1; }
    [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"][data-active="false"] {
      opacity: 0.4;
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%); }
      [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"][data-active="false"] [data-type="@c.daterange"] {
        pointer-events: none; }
    [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"] [data-type="@e.icon"][data-variation="times"] {
      content: '';
      position: absolute;
      top: 1em;
      right: 1em;
      opacity: 0.5; }
      [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"] [data-type="@e.icon"][data-variation="times"]:hover {
        opacity: 0.7; }
    [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"] [data-part="tab-id"] {
      color: #40A8DE;
      margin-bottom: 0.5em;
      display: flex;
      align-items: center; }
      [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"] [data-part="tab-id"] [data-type="@e.icon"] {
        margin-right: 0.3em; }
      [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"] [data-part="tab-id"][data-is-event="true"] {
        color: #fdc02f; }
    [data-type="@e.panel"].-comparison > [data-part="header"] [data-part="tab"] [data-part="tab-info"] {
      color: #95989A; }
  [data-type="@e.panel"].-comparison > [data-part="header"] [data-type="@e.icon"][data-variation="addcircle"] {
    width: 1.8em;
    height: 1.8em;
    background-color: transparent;
    opacity: 0.5;
    margin-left: 1em; }
    [data-type="@e.panel"].-comparison > [data-part="header"] [data-type="@e.icon"][data-variation="addcircle"] svg > *:not([fill="none"]) {
      fill: #606262; }
    [data-type="@e.panel"].-comparison > [data-part="header"] [data-type="@e.icon"][data-variation="addcircle"]:hover {
      opacity: 1;
      background-color: transparent; }
      [data-type="@e.panel"].-comparison > [data-part="header"] [data-type="@e.icon"][data-variation="addcircle"]:hover svg > *:not([fill="none"]) {
        fill: #40A8DE; }
    [data-type="@e.panel"].-comparison > [data-part="header"] [data-type="@e.icon"][data-variation="addcircle"][data-empty="true"]:after {
      content: 'ADD GROUP';
      position: relative;
      left: 120%;
      white-space: nowrap; }

[data-type="@e.panel"].-comparison > [data-part="body"] {
  padding: 3rem 2.5rem; }

[data-type="@e.panel"].-comparison [data-part="analyse"] {
  position: relative;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s; }
  [data-type="@e.panel"].-comparison [data-part="analyse"] [data-part="header"] {
    display: flex;
    margin-bottom: 0.5em;
    justify-content: space-between; }
    [data-type="@e.panel"].-comparison [data-part="analyse"] [data-part="header"] [data-part='controls'] {
      display: flex; }
      [data-type="@e.panel"].-comparison [data-part="analyse"] [data-part="header"] [data-part='controls'] [data-type="@e.button"] {
        margin-right: 0.5em;
        padding: 0.3em 0.8em;
        opacity: 0.4;
        -webkit-transition: opacity 0.2s;
        transition: opacity 0.2s; }
        [data-type="@e.panel"].-comparison [data-part="analyse"] [data-part="header"] [data-part='controls'] [data-type="@e.button"] [data-type="@e.dot"] {
          color: #F8F8F8;
          width: 1.8em;
          height: 1.8em;
          background: #95989A;
          color: white; }
        [data-type="@e.panel"].-comparison [data-part="analyse"] [data-part="header"] [data-part='controls'] [data-type="@e.button"][data-active="true"] {
          opacity: 1; }
          [data-type="@e.panel"].-comparison [data-part="analyse"] [data-part="header"] [data-part='controls'] [data-type="@e.button"][data-active="true"] [data-type="@e.dot"] {
            background: #40A8DE;
            color: white; }
        [data-type="@e.panel"].-comparison [data-part="analyse"] [data-part="header"] [data-part='controls'] [data-type="@e.button"]:not([data-active="true"]):hover {
          opacity: 0.7; }
  [data-type="@e.panel"].-comparison [data-part="analyse"] > [data-type="@e.icon"][data-variation="cog"] {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0;
    pointer-events: none; }
  [data-type="@e.panel"].-comparison [data-part="analyse"][data-status='fetching'] {
    opacity: 0.3; }
    [data-type="@e.panel"].-comparison [data-part="analyse"][data-status='fetching'] > [data-type="@e.icon"][data-variation="cog"] {
      width: 2em;
      height: 2em;
      opacity: 0.8;
      -webkit-animation: spinaa 2s linear infinite;
              animation: spinaa 2s linear infinite;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%; }

@-webkit-keyframes spinaa {
  0% {
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
            transform: rotate(0deg) translate(-50%, -50%); }
  100% {
    -webkit-transform: rotate(360deg) translate(-50%, -50%);
            transform: rotate(360deg) translate(-50%, -50%); } }

@keyframes spinaa {
  0% {
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
            transform: rotate(0deg) translate(-50%, -50%); }
  100% {
    -webkit-transform: rotate(360deg) translate(-50%, -50%);
            transform: rotate(360deg) translate(-50%, -50%); } }


/**********

	COLOURS : EVS V3

**********/
[data-part='timeline'][data-name='monitors'] [data-part="message"] {
  padding: 1em;
  border: 1px dashed white;
  display: block;
  width: 25rem;
  text-align: center;
  text-transform: uppercase;
  margin: 2em auto;
  opacity: 0.3; }

[data-part='timeline'][data-name='times'] {
  position: relative;
  font-size: 0.95em;
  padding-top: 0.5em;
  margin-top: 1em; }
  [data-part='timeline'][data-name='times'] > * {
    display: block; }
  [data-part='timeline'][data-name='times'] [data-part='bar'] {
    position: relative;
    height: 0.2em;
    background: rgba(248, 248, 248, 0.3); }
  [data-part='timeline'][data-name='times'] [data-part='lines'] {
    height: 0.6em;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(248, 248, 248, 0.3)), color-stop(3%, transparent));
    background-image: -webkit-linear-gradient(left, rgba(248, 248, 248, 0.3) 0%, transparent 3%);
    background-image: linear-gradient(to right, rgba(248, 248, 248, 0.3) 0%, transparent 3%);
    background-size: 5em 1em;
    background-repeat: repeat-x; }
  [data-part='timeline'][data-name='times'] [data-part='dates'] {
    display: flex;
    margin-top: 0.5em;
    justify-content: space-between; }

[data-part='timeline'][data-name='backtracks'], [data-part='timeline'][data-name='observations'], [data-part='timeline'][data-name='events'] {
  max-height: 0;
  height: 100vh;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 0;
  margin: 0;
  border-top: 0 solid rgba(248, 248, 248, 0.05); }
  [data-part='timeline'][data-name='backtracks'] > *, [data-part='timeline'][data-name='observations'] > *, [data-part='timeline'][data-name='events'] > * {
    opacity: 0.5; }
    [data-part='timeline'][data-name='backtracks'] > *:hover, [data-part='timeline'][data-name='observations'] > *:hover, [data-part='timeline'][data-name='events'] > *:hover {
      opacity: 1;
      background-color: transparent; }
      [data-part='timeline'][data-name='backtracks'] > *:hover svg > *:not([fill="none"]), [data-part='timeline'][data-name='observations'] > *:hover svg > *:not([fill="none"]), [data-part='timeline'][data-name='events'] > *:hover svg > *:not([fill="none"]) {
        fill: #40A8DE; }
  [data-part='timeline'][data-name='backtracks'][data-open='true']:not(:last-child), [data-part='timeline'][data-name='observations'][data-open='true']:not(:last-child), [data-part='timeline'][data-name='events'][data-open='true']:not(:last-child) {
    border-bottom-width: 0.1em; }
  [data-part='timeline'][data-name='backtracks'] [data-type="@e.hint"], [data-part='timeline'][data-name='observations'] [data-type="@e.hint"], [data-part='timeline'][data-name='events'] [data-type="@e.hint"] {
    z-index: 1;
    position: relative;
    font-size: 1.2rem; }
    [data-part='timeline'][data-name='backtracks'] [data-type="@e.hint"] [data-part="popup"], [data-part='timeline'][data-name='observations'] [data-type="@e.hint"] [data-part="popup"], [data-part='timeline'][data-name='events'] [data-type="@e.hint"] [data-part="popup"] {
      background: #F8F8F8;
      color: #0B1319; }
      [data-part='timeline'][data-name='backtracks'] [data-type="@e.hint"] [data-part="popup"]:before, [data-part='timeline'][data-name='observations'] [data-type="@e.hint"] [data-part="popup"]:before, [data-part='timeline'][data-name='events'] [data-type="@e.hint"] [data-part="popup"]:before {
        border-top-color: #F8F8F8; }
      [data-part='timeline'][data-name='backtracks'] [data-type="@e.hint"] [data-part="popup"] [data-part="popup-title"], [data-part='timeline'][data-name='observations'] [data-type="@e.hint"] [data-part="popup"] [data-part="popup-title"], [data-part='timeline'][data-name='events'] [data-type="@e.hint"] [data-part="popup"] [data-part="popup-title"] {
        color: #40A8DE; }
    [data-part='timeline'][data-name='backtracks'] [data-type="@e.hint"]:hover [data-type="@e.icon"], [data-part='timeline'][data-name='observations'] [data-type="@e.hint"]:hover [data-type="@e.icon"], [data-part='timeline'][data-name='events'] [data-type="@e.hint"]:hover [data-type="@e.icon"] {
      z-index: 2;
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
  [data-part='timeline'][data-name='backtracks'][data-open='true'], [data-part='timeline'][data-name='observations'][data-open='true'], [data-part='timeline'][data-name='events'][data-open='true'] {
    max-height: 2em;
    padding: 0.16em 0;
    margin: 0.4em 0;
    opacity: 1; }
    [data-part='timeline'][data-name='backtracks'][data-open='true']:nth-child(1), [data-part='timeline'][data-name='observations'][data-open='true']:nth-child(1), [data-part='timeline'][data-name='events'][data-open='true']:nth-child(1) {
      max-height: -webkit-fit-content;
      max-height: -moz-fit-content;
      max-height: fit-content; }

[data-part='timeline'][data-name='backtracks'] [data-type="@e.icon"]:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  width: 10rem;
  height: 1px;
  background: rgba(96, 98, 98, 0.5);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

[data-part='timeline'][data-name='backtracks'] [data-type="@e.icon"]:hover:after {
  background: #40A8DE; }

/**********

	COLOURS : EVS V3

**********/
[data-type="@e.marker.menu"] {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left: 1px solid rgba(248, 248, 248, 0.5);
  padding-bottom: 3rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  opacity: 1;
  max-height: 100rem;
  font-size: 1.1rem; }
  [data-type="@e.marker.menu"]:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0.5em;
    height: 0.5em;
    background: rgba(248, 248, 248, 0.5);
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  [data-type="@e.marker.menu"] [data-type="@e.icon"] {
    position: absolute; }
  [data-type="@e.marker.menu"] [data-part='nav'] {
    background: rgba(11, 19, 25, 0.8);
    display: flex;
    flex-direction: column;
    padding: 0.5em 0; }
    [data-type="@e.marker.menu"] [data-part='nav'] > * {
      display: block;
      white-space: nowrap;
      text-align: left; }
    [data-type="@e.marker.menu"] [data-part='nav'] [data-type="@e.button"] {
      padding: 0.5rem 1rem; }
  [data-type="@e.marker.menu"][data-open="false"] {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0; }

/**********

	COLOURS : EVS V3

**********/
[data-type="@e.marker.observation"] {
  width: 1.8rem;
  height: 1.8rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  opacity: 0.7;
  background: #F8F8F8;
  border-radius: 50%;
  box-shadow: 0 0 2em rgba(0, 0, 0, 0.5); }
  [data-type="@e.marker.observation"] [data-type="@e.hint"] {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    cursor: help; }
    [data-type="@e.marker.observation"] [data-type="@e.hint"] [data-type="@e.icon"] {
      width: 100%;
      height: 100%;
      background-color: transparent; }
      [data-type="@e.marker.observation"] [data-type="@e.hint"] [data-type="@e.icon"] svg > *:not([fill="none"]) {
        fill: red; }
  [data-type="@e.marker.observation"][data-visible="false"] {
    opacity: 0; }
  [data-type="@e.marker.observation"]:hover {
    opacity: 1;
    width: 2.4rem;
    height: 2.4rem;
    opacity: 1 !important; }

/**********

	COLOURS : EVS V3

**********/
[data-type="@e.marker.backtrack"] {
  width: 1.2rem;
  height: 1.2rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  opacity: 0.5; }
  [data-type="@e.marker.backtrack"] [data-type="@e.icon"] {
    width: 1.2em;
    height: 1.2em;
    background-color: transparent;
    display: block;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    [data-type="@e.marker.backtrack"] [data-type="@e.icon"] svg > *:not([fill="none"]) {
      fill: #40A8DE; }
  [data-type="@e.marker.backtrack"] [data-part="tail"] {
    width: 0;
    opacity: 0;
    height: 4rem;
    background-image: url(http://cms.envirosuite.com/wp-content/uploads/backtrack.svg?v1);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 50%;
    position: absolute;
    top: 110%;
    left: -40%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%; }
  [data-type="@e.marker.backtrack"][data-is-sticky="true"] [data-type="@e.icon"], [data-type="@e.marker.backtrack"]:hover [data-type="@e.icon"] {
    width: 1.4em;
    height: 1.4em;
    background-color: transparent; }
    [data-type="@e.marker.backtrack"][data-is-sticky="true"] [data-type="@e.icon"] svg > *:not([fill="none"]), [data-type="@e.marker.backtrack"]:hover [data-type="@e.icon"] svg > *:not([fill="none"]) {
      fill: #40A8DE; }
  [data-type="@e.marker.backtrack"][data-is-sticky="true"] [data-part="tail"], [data-type="@e.marker.backtrack"]:hover [data-part="tail"] {
    width: 40rem;
    opacity: 0.5; }
  [data-type="@e.marker.backtrack"][data-is-sticky="true"] {
    opacity: 1; }

/**********

	COLOURS : EVS V3

**********/
[data-type="@e.marker.event"] {
  width: 1rem;
  height: 1rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: width 0.5s, height 0.5s, -webkit-transform 0.2s;
  transition: width 0.5s, height 0.5s, -webkit-transform 0.2s;
  transition: width 0.5s, height 0.5s, transform 0.2s;
  transition: width 0.5s, height 0.5s, transform 0.2s, -webkit-transform 0.2s;
  position: relative; }
  [data-type="@e.marker.event"] [data-type="@e.contextmenu"] {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    [data-type="@e.marker.event"] [data-type="@e.contextmenu"] [data-part="item"] [data-part="event-title"] {
      display: block;
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 1em;
      display: flex;
      align-items: center; }
      [data-type="@e.marker.event"] [data-type="@e.contextmenu"] [data-part="item"] [data-part="event-title"] [data-type='@e.icon'] {
        margin-right: 0.3em;
        background-color: transparent;
        width: 1.4em;
        height: 1.4em; }
        [data-type="@e.marker.event"] [data-type="@e.contextmenu"] [data-part="item"] [data-part="event-title"] [data-type='@e.icon'] svg > *:not([fill="none"]) {
          fill: #fdc02f; }
    [data-type="@e.marker.event"] [data-type="@e.contextmenu"] [data-part="item"] p {
      margin: 0;
      line-height: 1.6em;
      white-space: normal;
      opacity: 0.8; }
    [data-type="@e.marker.event"] [data-type="@e.contextmenu"] [data-part="item"] [data-type='@e.button'] {
      color: #40A8DE;
      background-color: #fff;
      padding: 0.5em calc(0.5em * 2);
      border: 1px solid #40A8DE;
      margin-top: 0.8em; }
    [data-type="@e.marker.event"] [data-type="@e.contextmenu"] [data-part="item"]:not(:last-child) {
      border-bottom: 1px solid #E4E7E8;
      margin-bottom: 0.5em; }
    [data-type="@e.marker.event"] [data-type="@e.contextmenu"] [data-part="item"] button {
      color: #40A8DE;
      margin-top: 2rem; }
  [data-type="@e.marker.event"] [data-type="@e.icon"] {
    width: 1.8em;
    height: 1.8em;
    background-color: transparent;
    display: block; }
    [data-type="@e.marker.event"] [data-type="@e.icon"] svg > *:not([fill="none"]) {
      fill: #fdc02f; }

/**********

	COLOURS : EVS V3

**********/
[data-type="@e.marker.sourceid"] {
  width: 1rem;
  height: 1rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  opacity: 0.5;
  width: 10rem;
  height: 10rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 50%;
  position: absolute;
  top: 120%;
  left: -27%;
  transition: all 0.3s; }

/**********

	COLOURS : EVS V3

**********/
[data-type="@e.marker.sourceid"] {
  width: 1rem;
  height: 1rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  opacity: 0.5;
  width: 10rem;
  height: 10rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 50%;
  position: absolute;
  top: 120%;
  left: -27%;
  transition: all 0.3s; }

/**********

	COLOURS : EVS V3

**********/
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

[data-type="@e.marker.monitor"] {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: width 0.5s, height 0.5s, -webkit-transform 0.2s;
  transition: width 0.5s, height 0.5s, -webkit-transform 0.2s;
  transition: width 0.5s, height 0.5s, transform 0.2s;
  transition: width 0.5s, height 0.5s, transform 0.2s, -webkit-transform 0.2s;
  position: relative;
  z-index: 1;
  /*
		thresholds
	*/ }
  [data-type="@e.marker.monitor"] [data-part="detail"] {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 2;
    text-align: center;
    background: #0B1319;
    cursor: pointer;
    color: currentColor; }
    [data-type="@e.marker.monitor"] [data-part="detail"] [data-part="detail-reading"] {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 60%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1em;
      z-index: 2; }
      [data-type="@e.marker.monitor"] [data-part="detail"] [data-part="detail-reading"] [data-part='sparkline'] {
        width: 60%;
        margin-top: 0.5em; }
    [data-type="@e.marker.monitor"] [data-part="detail"] svg[data-type='@e.horseshoe-progress'] {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      -webkit-transform: translate(-50%, -50%) rotate(135deg);
              transform: translate(-50%, -50%) rotate(135deg);
      z-index: 3; }
      [data-type="@e.marker.monitor"] [data-part="detail"] svg[data-type='@e.horseshoe-progress'] [data-part="progress"] {
        stroke: currentColor; }
  [data-type="@e.marker.monitor"] [data-part="controls"] {
    position: absolute;
    z-index: 4;
    opacity: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
    overflow: hidden; }
    [data-type="@e.marker.monitor"] [data-part="controls"]:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4.5rem;
      height: 2rem;
      z-index: -1;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    [data-type="@e.marker.monitor"] [data-part="controls"] > [data-type="@e.icon"],
    [data-type="@e.marker.monitor"] [data-part="controls"] > [data-type='@e.contextmenu'] {
      position: absolute;
      top: 50%;
      margin: 0 0.1em;
      opacity: 0.5; }
      [data-type="@e.marker.monitor"] [data-part="controls"] > [data-type="@e.icon"]:hover,
      [data-type="@e.marker.monitor"] [data-part="controls"] > [data-type='@e.contextmenu']:hover {
        opacity: 1; }
    [data-type="@e.marker.monitor"] [data-part="controls"] > [data-type="@e.icon"],
    [data-type="@e.marker.monitor"] [data-part="controls"] > [data-type='@e.contextmenu'] [data-part="trigger"] [data-type="@e.icon"] {
      width: 1.8rem;
      height: 1.8rem;
      background-color: transparent;
      background: #95989A;
      border-radius: 50%;
      cursor: pointer; }
      [data-type="@e.marker.monitor"] [data-part="controls"] > [data-type="@e.icon"] svg > *:not([fill="none"]),
      [data-type="@e.marker.monitor"] [data-part="controls"] > [data-type='@e.contextmenu'] [data-part="trigger"] [data-type="@e.icon"] svg > *:not([fill="none"]) {
        fill: #0B1319; }
      [data-type="@e.marker.monitor"] [data-part="controls"] > [data-type="@e.icon"] svg,
      [data-type="@e.marker.monitor"] [data-part="controls"] > [data-type='@e.contextmenu'] [data-part="trigger"] [data-type="@e.icon"] svg {
        padding: 0.3em; }
    [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] {
      font-size: 1rem;
      right: 0%; }
      [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='options'] {
        left: unset;
        right: unset;
        top: unset;
        bottom: unset;
        -webkit-transform: none;
                transform: none;
        flex-direction: column;
        top: 100%;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
        [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] {
          height: auto;
          width: auto; }
          [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
            right: unset;
            left: unset;
            -webkit-transform: none;
                    transform: none; }
        [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] {
          height: 0.6em; }
          [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
            -webkit-transform: rotate(180deg) translateX(50%);
                    transform: rotate(180deg) translateX(50%); }
        [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
          left: 50%;
          -webkit-transform: rotate(180deg) translateX(50%);
                  transform: rotate(180deg) translateX(50%); }
      [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='options'] [data-part='pointer'] svg {
        fill: black; }
      [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] {
        background: black;
        color: white; }
        [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='options'] [data-part='children'] [data-part='options-group'] > [data-part='title'] {
          color: rgba(255, 255, 255, 0.7); }
      [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='options'] [data-part='options-group']:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
      [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='item'] {
        justify-content: center; }
        [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part='item'] > * {
          margin: 0 0.4em; }
      [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part="options-group"].breakdown [data-part="item"] {
        flex-direction: column;
        width: 100%;
        height: auto; }
        [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part="options-group"].breakdown [data-part="item"] [data-type="@e.icon"] {
          width: 100%;
          height: auto; }
        [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part="options-group"].breakdown [data-part="item"] svg {
          display: block;
          width: 100%;
          height: auto;
          position: relative; }
      [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part="conditions-group"] {
        margin: 0 0.3em;
        display: flex;
        flex-direction: column;
        align-items: center; }
        [data-type="@e.marker.monitor"] [data-part="controls"] [data-type='@e.contextmenu'] [data-part="conditions-group"] [data-type="@e.icon"] {
          margin-right: 0.2em; }
  [data-type="@e.marker.monitor"] [data-part="pollution-rose"],
  [data-type="@e.marker.monitor"] [data-part="influence-arc"] {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 35rem;
    height: 35rem;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: -1; }
  [data-type="@e.marker.monitor"] [data-part="pollution-rose"] {
    -webkit-transform: translate(-50%, -50%) rotate(-90deg);
            transform: translate(-50%, -50%) rotate(-90deg); }
  [data-type="@e.marker.monitor"] svg.blur {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    [data-type="@e.marker.monitor"] svg.blur .color-start {
      -webkit-transition: stop-color 0.5s;
      transition: stop-color 0.5s; }
    [data-type="@e.marker.monitor"] svg.blur .color-start,
    [data-type="@e.marker.monitor"] svg.blur .color-end {
      stop-color: transparent; }
  [data-type="@e.marker.monitor"]:hover {
    -webkit-transform: translate(-50%, -50%) scale(1.2);
            transform: translate(-50%, -50%) scale(1.2); }
    [data-type="@e.marker.monitor"]:hover [data-part="controls"] {
      opacity: 1;
      pointer-events: all;
      overflow: visible; }
  [data-type="@e.marker.monitor"][data-selected=true] {
    -webkit-transform: translate(-50%, -50%) scale(1.2);
            transform: translate(-50%, -50%) scale(1.2); }
    [data-type="@e.marker.monitor"][data-selected=true]:before {
      width: calc(100% + 2rem);
      height: calc(100% + 2rem);
      opacity: 0.15; }
  [data-type="@e.marker.monitor"]:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    z-index: -10;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    background: currentColor;
    opacity: 0; }
  [data-type="@e.marker.monitor"][data-has-particulate="false"] {
    width: 0.7em !important;
    height: 0.7em !important;
    background: #0B1319;
    border: 1px solid #606262; }
    [data-type="@e.marker.monitor"][data-has-particulate="false"] [data-part="select-particulate"] {
      position: absolute;
      left: 50%;
      top: 50%;
      display: flex;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 0;
      max-height: 0;
      overflow: hidden; }
      [data-type="@e.marker.monitor"][data-has-particulate="false"] [data-part="select-particulate"] [data-type="@c.particulate"] {
        margin: 0 0.05em; }
    [data-type="@e.marker.monitor"][data-has-particulate="false"]:hover [data-part="select-particulate"] {
      opacity: 1;
      max-width: 10rem;
      max-height: 10rem; }
  [data-type="@e.marker.monitor"][data-threshold="1"] {
    color: #adda48; }
    [data-type="@e.marker.monitor"][data-threshold="1"] svg.blur .color-start {
      stop-color: #adda48; }
    [data-type="@e.marker.monitor"][data-threshold="1"] svg.blur .color-end {
      stop-color: rgba(173, 218, 72, 0); }
    [data-type="@e.marker.monitor"][data-threshold="1"] svg[data-part="influence-arc"] .inner {
      fill: rgba(173, 218, 72, 0.25); }
    [data-type="@e.marker.monitor"][data-threshold="1"] svg[data-part="influence-arc"] .border {
      fill: rgba(173, 218, 72, 0.4); }
  [data-type="@e.marker.monitor"][data-threshold="2"] {
    color: #FEE84E; }
    [data-type="@e.marker.monitor"][data-threshold="2"] svg.blur .color-start {
      stop-color: #FEE84E; }
    [data-type="@e.marker.monitor"][data-threshold="2"] svg.blur .color-end {
      stop-color: rgba(254, 232, 78, 0); }
    [data-type="@e.marker.monitor"][data-threshold="2"] svg[data-part="influence-arc"] .inner {
      fill: rgba(254, 232, 78, 0.25); }
    [data-type="@e.marker.monitor"][data-threshold="2"] svg[data-part="influence-arc"] .border {
      fill: rgba(254, 232, 78, 0.4); }
  [data-type="@e.marker.monitor"][data-threshold="3"] {
    color: #fdc02f; }
    [data-type="@e.marker.monitor"][data-threshold="3"] svg.blur .color-start {
      stop-color: #fdc02f; }
    [data-type="@e.marker.monitor"][data-threshold="3"] svg.blur .color-end {
      stop-color: rgba(253, 192, 47, 0); }
    [data-type="@e.marker.monitor"][data-threshold="3"] svg[data-part="influence-arc"] .inner {
      fill: rgba(253, 192, 47, 0.25); }
    [data-type="@e.marker.monitor"][data-threshold="3"] svg[data-part="influence-arc"] .border {
      fill: rgba(253, 192, 47, 0.4); }
  [data-type="@e.marker.monitor"][data-threshold="4"] {
    color: #e62465; }
    [data-type="@e.marker.monitor"][data-threshold="4"] svg.blur .color-start {
      stop-color: #e62465; }
    [data-type="@e.marker.monitor"][data-threshold="4"] svg.blur .color-end {
      stop-color: rgba(230, 36, 101, 0); }
    [data-type="@e.marker.monitor"][data-threshold="4"] svg[data-part="influence-arc"] .inner {
      fill: rgba(230, 36, 101, 0.25); }
    [data-type="@e.marker.monitor"][data-threshold="4"] svg[data-part="influence-arc"] .border {
      fill: rgba(230, 36, 101, 0.4); }
  [data-type="@e.marker.monitor"][data-threshold="5"] {
    color: #E057CD; }
    [data-type="@e.marker.monitor"][data-threshold="5"] svg.blur .color-start {
      stop-color: #E057CD; }
    [data-type="@e.marker.monitor"][data-threshold="5"] svg.blur .color-end {
      stop-color: rgba(224, 87, 205, 0); }
    [data-type="@e.marker.monitor"][data-threshold="5"] svg[data-part="influence-arc"] .inner {
      fill: rgba(224, 87, 205, 0.25); }
    [data-type="@e.marker.monitor"][data-threshold="5"] svg[data-part="influence-arc"] .border {
      fill: rgba(224, 87, 205, 0.4); }
    [data-type="@e.marker.monitor"][data-threshold="5"]:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      border: 2px solid #E057CD;
      border-radius: 50%;
      -webkit-animation: blip 2s ease-in-out infinite;
              animation: blip 2s ease-in-out infinite;
      z-index: -1; }

@-webkit-keyframes blip {
  0% {
    opacity: 0;
    width: 100%;
    height: 100%; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0;
    width: calc(100% + 2em);
    height: calc(100% + 2em); } }

@keyframes blip {
  0% {
    opacity: 0;
    width: 100%;
    height: 100%; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0;
    width: calc(100% + 2em);
    height: calc(100% + 2em); } }

/**********

	COLOURS : EVS V3

**********/
[data-type="@e.marker.sourceid"] {
  width: 1rem;
  height: 1rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  opacity: 0.5;
  width: 10rem;
  height: 10rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 50%;
  position: absolute;
  top: 120%;
  left: -27%;
  transition: all 0.3s; }

/**********

	COLOURS : EVS V3

**********/
[data-type='@c.map'] {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  [data-type='@c.map'] .leaflet-container {
    position: relative;
    overflow: hidden;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #0B1319; }
    [data-type='@c.map'] .leaflet-container .leaflet-marker-pane > * {
      width: 0 !important;
      height: 0 !important; }
    [data-type='@c.map'] .leaflet-container .leaflet-marker-pane .leaflet-interactive:hover {
      z-index: 10000 !important;
      position: relative; }
    [data-type='@c.map'] .leaflet-container .leaflet-interactive {
      cursor: auto;
      cursor: initial; }
    [data-type='@c.map'] .leaflet-container .leaflet-top.leaflet-left {
      position: absolute;
      top: 10rem;
      right: 1rem;
      left: unset; }
      [data-type='@c.map'] .leaflet-container .leaflet-top.leaflet-left .leaflet-control-zoom {
        margin: 0; }
      [data-type='@c.map'] .leaflet-container .leaflet-top.leaflet-left .leaflet-control-zoom-in,
      [data-type='@c.map'] .leaflet-container .leaflet-top.leaflet-left .leaflet-control-zoom-out {
        width: 2rem;
        height: 2rem;
        line-height: 1.8rem;
        font-size: 1.2em; }
  [data-type='@c.map'] [data-part="map-state"] {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3; }
    [data-type='@c.map'] [data-part="map-state"] [data-part="interface-container"] {
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: auto;
      z-index: 2; }
      [data-type='@c.map'] [data-part="map-state"] [data-part="interface-container"] [data-part="map-date"] {
        position: absolute;
        z-index: 4;
        bottom: calc(100% + 1em);
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
    [data-type='@c.map'] [data-part="map-state"] [data-part="map-nav"] {
      position: relative;
      z-index: 3; }
  [data-type='@c.map'] [data-part="map-controls"] {
    z-index: 3;
    position: absolute;
    top: 10%;
    left: 2rem;
    width: auto;
    z-index: 2; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"] [data-part="detail"],
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"] [data-part="controls"],
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"] [data-part="pollution-rose"],
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"] [data-part="influence-arc"] {
    display: none; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"]:not([data-trendrate^="-"]) {
    color: #e62465 !important;
    border: 2px solid #e62465; }
    [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"]:not([data-trendrate^="-"]) [data-part="detail"]:after {
      background: #e62465; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate^="-"] {
    color: #adda48 !important;
    border: 2px solid #adda48; }
    [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate^="-"] [data-part="detail"]:after {
      background: #adda48; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="NaN"] {
    color: #0B1319 !important;
    border: 2px solid #0B1319; }
    [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="NaN"] [data-part="detail"]:after {
      background: #0B1319; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-0"] {
    opacity: 0;
    max-width: 0em;
    max-height: 0em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="0"] {
    opacity: 0;
    max-width: 0em;
    max-height: 0em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-1"] {
    opacity: 0.08;
    max-width: 0.3em;
    max-height: 0.3em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="1"] {
    opacity: 0.08;
    max-width: 0.3em;
    max-height: 0.3em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-2"] {
    opacity: 0.16;
    max-width: 0.6em;
    max-height: 0.6em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="2"] {
    opacity: 0.16;
    max-width: 0.6em;
    max-height: 0.6em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-3"] {
    opacity: 0.24;
    max-width: 0.9em;
    max-height: 0.9em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="3"] {
    opacity: 0.24;
    max-width: 0.9em;
    max-height: 0.9em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-4"] {
    opacity: 0.32;
    max-width: 1.2em;
    max-height: 1.2em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="4"] {
    opacity: 0.32;
    max-width: 1.2em;
    max-height: 1.2em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-5"] {
    opacity: 0.4;
    max-width: 1.5em;
    max-height: 1.5em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="5"] {
    opacity: 0.4;
    max-width: 1.5em;
    max-height: 1.5em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-6"] {
    opacity: 0.48;
    max-width: 1.8em;
    max-height: 1.8em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="6"] {
    opacity: 0.48;
    max-width: 1.8em;
    max-height: 1.8em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-7"] {
    opacity: 0.56;
    max-width: 2.1em;
    max-height: 2.1em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="7"] {
    opacity: 0.56;
    max-width: 2.1em;
    max-height: 2.1em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-8"] {
    opacity: 0.64;
    max-width: 2.4em;
    max-height: 2.4em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="8"] {
    opacity: 0.64;
    max-width: 2.4em;
    max-height: 2.4em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-9"] {
    opacity: 0.72;
    max-width: 2.7em;
    max-height: 2.7em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="9"] {
    opacity: 0.72;
    max-width: 2.7em;
    max-height: 2.7em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="-10"] {
    opacity: 0.8;
    max-width: 3em;
    max-height: 3em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"][data-trendrate="10"] {
    opacity: 0.8;
    max-width: 3em;
    max-height: 3em; }
  [data-type='@c.map'][data-trending='true'] [data-type="@e.marker.monitor"] svg.blur {
    opacity: 0.1 !important; }
  [data-type='@c.map'] [data-type="@e.marker.monitor"] {
    width: 0.6em;
    height: 0.6em; }
    [data-type='@c.map'] [data-type="@e.marker.monitor"] [data-part="detail"] {
      opacity: 0; }
      [data-type='@c.map'] [data-type="@e.marker.monitor"] [data-part="detail"] [data-part="detail-reading"] {
        font-size: 1.1rem; }
        [data-type='@c.map'] [data-type="@e.marker.monitor"] [data-part="detail"] [data-part="detail-reading"] [data-part="value"] {
          margin: 0.2em 0; }
        [data-type='@c.map'] [data-type="@e.marker.monitor"] [data-part="detail"] [data-part="detail-reading"] [data-part="particulate"],
        [data-type='@c.map'] [data-type="@e.marker.monitor"] [data-part="detail"] [data-part="detail-reading"] > svg {
          display: none; }
      [data-type='@c.map'] [data-type="@e.marker.monitor"] [data-part="detail"] [data-type="@e.horseshoe-progress"] {
        opacity: 0; }
    [data-type='@c.map'] [data-type="@e.marker.monitor"] [data-part="pollution-rose"],
    [data-type='@c.map'] [data-type="@e.marker.monitor"] [data-part="influence-arc"] {
      max-width: 0;
      max-height: 0;
      opacity: 0;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out; }
    [data-type='@c.map'] [data-type="@e.marker.monitor"] svg.blur {
      width: 0;
      height: 0;
      pointer-events: none;
      opacity: 0; }
  [data-type='@c.map'][data-zoom-level='|'] [data-type="@e.marker.monitor"][data-has-particulate="true"] {
    opacity: 0.2;
    pointer-events: none;
    border: 1px solid currentColor;
    background: transparent; }
    [data-type='@c.map'][data-zoom-level='|'] [data-type="@e.marker.monitor"][data-has-particulate="true"][data-threshold="4"] {
      opacity: 0.5; }
    [data-type='@c.map'][data-zoom-level='|'] [data-type="@e.marker.monitor"][data-has-particulate="true"] svg.blur {
      opacity: 0.5;
      width: 20rem;
      height: 20rem; }
  [data-type='@c.map'][data-zoom-level='|'] .leaflet-image-layer.heatmap {
    opacity: 0.6; }
  [data-type='@c.map'][data-zoom-level*='|'][data-heatmap='false'] [data-type="@e.marker.monitor"][data-has-particulate="true"] {
    opacity: 0.7; }
    [data-type='@c.map'][data-zoom-level*='|'][data-heatmap='false'] [data-type="@e.marker.monitor"][data-has-particulate="true"] svg.blur {
      display: none; }
  [data-type='@c.map'][data-zoom-level*='||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] {
    width: 0.7em;
    height: 0.7em;
    background: currentColor; }
    [data-type='@c.map'][data-zoom-level*='||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] svg.blur {
      opacity: 0.1;
      width: 25rem;
      height: 25rem; }
  [data-type='@c.map'][data-zoom-level*='||'] .leaflet-image-layer.heatmap {
    opacity: 0.4; }
  [data-type='@c.map'][data-zoom-level*='|||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] {
    width: 2em;
    height: 2em;
    background: #0B1319; }
    [data-type='@c.map'][data-zoom-level*='|||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="detail"] {
      opacity: 1; }
    [data-type='@c.map'][data-zoom-level*='|||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] svg.blur {
      opacity: 0.03;
      width: 50rem;
      height: 50rem; }
  [data-type='@c.map'][data-zoom-level*='|||'] .leaflet-image-layer.heatmap {
    opacity: 0.1; }
  [data-type='@c.map'][data-zoom-level*='||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] {
    width: 3.5em;
    height: 3.5em; }
    [data-type='@c.map'][data-zoom-level*='||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="detail"] [data-part="detail-reading"] [data-part="value"] {
      font-size: 1.3rem; }
    [data-type='@c.map'][data-zoom-level*='||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="detail"] [data-type="@e.horseshoe-progress"] {
      opacity: 1; }
    [data-type='@c.map'][data-zoom-level*='||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="controls"] {
      top: 90%; }
    [data-type='@c.map'][data-zoom-level*='||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] svg.blur {
      opacity: 0;
      width: 0;
      height: 0; }
  [data-type='@c.map'][data-zoom-level*='|||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] {
    width: 6em;
    height: 6em; }
    [data-type='@c.map'][data-zoom-level*='|||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="detail"] [data-part="detail-reading"] {
      font-size: 1.2rem; }
      [data-type='@c.map'][data-zoom-level*='|||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="detail"] [data-part="detail-reading"] [data-part="value"] {
        font-size: 2rem; }
      [data-type='@c.map'][data-zoom-level*='|||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="detail"] [data-part="detail-reading"] [data-part="particulate"],
      [data-type='@c.map'][data-zoom-level*='|||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="detail"] [data-part="detail-reading"] > svg {
        display: block; }
    [data-type='@c.map'][data-zoom-level*='|||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="controls"] {
      top: 85%; }
  [data-type='@c.map'][data-zoom-level*='|||||'][data-marker="pollution"] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="pollution-rose"] {
    max-width: 25rem;
    max-height: 25rem;
    opacity: 1; }
    [data-type='@c.map'][data-zoom-level*='|||||'][data-marker="pollution"] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="pollution-rose"][data-visible="false"] {
      max-width: 0;
      max-height: 0; }
  [data-type='@c.map'][data-zoom-level*='|||||'][data-marker="influence"] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="influence-arc"] {
    max-width: 50rem;
    max-height: 50rem;
    opacity: 1; }
    [data-type='@c.map'][data-zoom-level*='|||||'][data-marker="influence"] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="influence-arc"][data-visible="false"] {
      max-width: 0;
      max-height: 0; }
  [data-type='@c.map'][data-zoom-level*='||||||'] [data-type="@e.marker.monitor"][data-has-particulate="true"] {
    width: 8em;
    height: 8em; }
  [data-type='@c.map'][data-zoom-level*='||||||'][data-marker="pollution"] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="pollution-rose"] {
    max-width: 35rem;
    max-height: 35rem; }
  [data-type='@c.map'][data-zoom-level*='||||||'][data-marker="influence"] [data-type="@e.marker.monitor"][data-has-particulate="true"] [data-part="influence-arc"] {
    max-width: 70rem;
    max-height: 70rem;
    width: 70rem;
    height: 70rem; }

/**********

	COLOURS : EVS V3

**********/
[data-type="@c.daterange.picker"] {
  border: 1px solid rgba(149, 152, 154, 0.2);
  padding: 0.5em 1em; }
  [data-type="@c.daterange.picker"] [data-type="@e.contextmenu"] {
    display: block; }
    [data-type="@c.daterange.picker"] [data-type="@e.contextmenu"] [data-part="trigger"] {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      [data-type="@c.daterange.picker"] [data-type="@e.contextmenu"] [data-part="trigger"] [data-type="@e.icon"] {
        margin-left: 0.5em; }
    [data-type="@c.daterange.picker"] [data-type="@e.contextmenu"][data-open="true"] [data-part="trigger"] [data-type="@e.icon"] {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

[data-type="@c.daterange"] {
  display: flex; }
  [data-type="@c.daterange"] [data-type="@e.date"] {
    font-size: 1.2rem; }
    [data-type="@c.daterange"] [data-type="@e.date"]:first-child:after {
      content: "-\00a0"; }
    [data-type="@c.daterange"] [data-type="@e.date"] [data-part="value"] [data-part="part"].time {
      font-size: 1.2rem;
      opacity: 0.75; }

/**********

	COLOURS : EVS V3

**********/
[data-type='@c.particulate'] {
  position: relative;
  cursor: pointer; }
  [data-type='@c.particulate'] [data-type='@e.dot'] {
    display: block;
    color: #0B1319;
    width: 2em;
    height: 2em; }
  [data-type='@c.particulate'][data-active="true"]:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 0.6em);
    height: calc(100% + 0.6em);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: 1px solid;
    border-radius: 50%; }
  [data-type='@c.particulate'][data-threshold="-1"] [data-type='@e.dot'] {
    background: #0B1319; }
  [data-type='@c.particulate'][data-threshold="-1"][data-active="true"]:after {
    border-color: #0B1319; }
  [data-type='@c.particulate'][data-threshold="1"] [data-type='@e.dot'] {
    background: #adda48; }
  [data-type='@c.particulate'][data-threshold="1"][data-active="true"]:after {
    border-color: #adda48; }
  [data-type='@c.particulate'][data-threshold="2"] [data-type='@e.dot'] {
    background: #FEE84E; }
  [data-type='@c.particulate'][data-threshold="2"][data-active="true"]:after {
    border-color: #FEE84E; }
  [data-type='@c.particulate'][data-threshold="3"] [data-type='@e.dot'] {
    background: #fdc02f; }
  [data-type='@c.particulate'][data-threshold="3"][data-active="true"]:after {
    border-color: #fdc02f; }
  [data-type='@c.particulate'][data-threshold="4"] [data-type='@e.dot'] {
    background: #e62465; }
  [data-type='@c.particulate'][data-threshold="4"][data-active="true"]:after {
    border-color: #e62465; }
  [data-type='@c.particulate'][data-threshold="5"] [data-type='@e.dot'] {
    background: #E057CD; }
  [data-type='@c.particulate'][data-threshold="5"][data-active="true"]:after {
    border-color: #E057CD; }

/**********

	COLOURS : EVS V3

**********/
[data-type="@c.timeline"] {
  position: relative;
  font-size: 0.95em;
  padding-top: 0.5em;
  margin-top: 1em; }
  [data-type="@c.timeline"] > * {
    display: block; }
  [data-type="@c.timeline"] [data-part='bar'] {
    position: relative;
    height: 0.4em;
    background: rgba(248, 248, 248, 0.3); }
    [data-type="@c.timeline"] [data-part='bar']:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 70%;
      height: 100%;
      background: #F8F8F8; }
    [data-type="@c.timeline"] [data-part='bar']:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 70%;
      width: 1em;
      height: 1em;
      background: #40A8DE;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      border-radius: 50%;
      border: 1.5px solid #0B1319; }
  [data-type="@c.timeline"] [data-part='lines'] {
    height: 0.6em;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(248, 248, 248, 0.3)), color-stop(3%, transparent));
    background-image: -webkit-linear-gradient(left, rgba(248, 248, 248, 0.3) 0%, transparent 3%);
    background-image: linear-gradient(to right, rgba(248, 248, 248, 0.3) 0%, transparent 3%);
    background-size: 5em 1em;
    background-repeat: repeat-x; }
  [data-type="@c.timeline"] [data-part='dates'] {
    display: flex;
    margin-top: 0.5em;
    justify-content: space-between; }

/**********

	COLOURS : EVS V3

**********/
[data-type='@l.map'] {
  position: relative;
  width: 100vw;
  height: 100vh;
  color: white;
  background: #0B1319;
  overflow: hidden; }
  [data-type='@l.map'] [data-type='@c.map'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

/**********

	COLOURS : EVS V3

**********/
.app {
  background-color: #E4E7E8;
  min-height: 100vh;
  /*
		elements
	*/ }
  .app > ._mainnav {
    z-index: 3; }
  .app > .global-header {
    color: #F8F8F8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2; }
  .app [data-type='@e.breadcrumbs'] [data-type='@e.icon'] {
    width: 1em;
    height: 1em; }
  .app [data-type='@e.breadcrumbs'] [data-part="crumb"] {
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s; }
    .app [data-type='@e.breadcrumbs'] [data-part="crumb"]:not(:last-child) {
      opacity: 0.5; }
  .app [data-type='@c.map']
[data-type='@e.panel'] {
    font-size: 1.1rem; }
  .app [data-type='@e.contextmenu'] [data-part="cta"] {
    color: #40A8DE; }
  .app [data-type="@e.panel"] {
    background: #0E1D26;
    color: #f1e2d9; }
    .app [data-type="@e.panel"] [data-part="divider"] {
      background: rgba(241, 226, 217, 0.25); }
  .app .\@e.-panel {
    background: #E4E7E8; }
  .app .\@e.-button .\@e.-icon {
    width: 1.5em;
    height: 1.5em; }
  .app .\@e.-date {
    font-size: calc(1.2rem * 0.9); }
    .app .\@e.-date .date {
      font-weight: 600;
      font-size: 1.2rem; }
  .app [data-threshold-color][data-threshold-color="1"] {
    background: #adda48 !important; }
  .app [data-threshold-color][data-threshold-color="2"] {
    background: #FEE84E !important; }
  .app [data-threshold-color][data-threshold-color="3"] {
    background: #fdc02f !important; }
  .app [data-threshold-color][data-threshold-color="4"] {
    background: #e62465 !important; }
  .app [data-threshold-color][data-threshold-color="5"] {
    background: #E057CD !important; }
  .app .leaflet-container .leaflet-tile-pane img {
    -webkit-transform: scale(1.006);
            transform: scale(1.006); }
  .app .highcharts-container .highcharts-series path.highcharts-graph {
    stroke-width: 1 !important;
    -webkit-transition: stroke 1s;
    transition: stroke 1s; }
  .app .highcharts-container .highcharts-series.highcharts-series-hover path.highcharts-graph {
    stroke: #40A8DE !important;
    stroke-dasharray: 0 !important; }
  .app .highcharts-container .highcharts-plot-line,
  .app .highcharts-container .highcharts-plot-line-label {
    opacity: 0.3; }

/**********

	COLOURS : EVS V3

**********/
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.global-header .title {
  margin: 0 auto;
  text-indent: -9999px;
  background-image: url(/static/media/evs-logo-colour-light.da95c3ab.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  width: 14rem;
  height: 3.1rem; }

.global-header [data-type='@e.icon'] {
  width: 2em;
  height: 2em; }

.global-header [data-type='@e.breadcrumbs'] {
  margin-left: 1em; }

.global-header [data-type='@e.contextmenu'].events [data-part='options'] {
  left: unset;
  right: unset;
  top: unset;
  bottom: unset;
  -webkit-transform: none;
          transform: none;
  flex-direction: column;
  top: 100%;
  right: calc(100% - 2em); }
  .global-header [data-type='@e.contextmenu'].events [data-part='options'] [data-part='pointer'] {
    height: auto;
    width: auto; }
    .global-header [data-type='@e.contextmenu'].events [data-part='options'] [data-part='pointer'] svg {
      right: unset;
      left: unset;
      -webkit-transform: none;
              transform: none; }
  .global-header [data-type='@e.contextmenu'].events [data-part='options'] [data-part='pointer'] {
    height: 0.6em; }
    .global-header [data-type='@e.contextmenu'].events [data-part='options'] [data-part='pointer'] svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .global-header [data-type='@e.contextmenu'].events [data-part='options'] [data-part='pointer'] svg {
    right: 0.5em; }

.global-header [data-type='@e.contextmenu'].events [data-part="trigger"] [data-type='@e.icon'] {
  width: 1.6em;
  height: 1.6em;
  background-color: transparent; }
  .global-header [data-type='@e.contextmenu'].events [data-part="trigger"] [data-type='@e.icon'] svg > *:not([fill="none"]) {
    fill: #fdc02f; }

.global-header [data-type='@e.contextmenu'].events [data-part="trigger"] [data-type='@e.dot'] {
  position: absolute;
  top: 0;
  left: 100%;
  -webkit-transform: translate(-20%, -30%);
          transform: translate(-20%, -30%);
  background: #fdc02f;
  color: #0B1319; }

.global-header [data-type='@e.contextmenu'].events [data-part="options"] {
  width: 50rem; }

.global-header [data-type='@e.contextmenu'].events [data-part="options-group"] > * {
  display: block;
  margin: 0; }

.global-header [data-type='@e.contextmenu'].events [data-part="options-group"] [data-part="item"] {
  padding-bottom: 1em;
  flex-direction: column;
  align-items: flex-start; }
  .global-header [data-type='@e.contextmenu'].events [data-part="options-group"] [data-part="item"] [data-part="event-title"] {
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.4em;
    display: flex;
    align-items: center; }
    .global-header [data-type='@e.contextmenu'].events [data-part="options-group"] [data-part="item"] [data-part="event-title"] [data-type='@e.icon'] {
      margin-right: 0.3em;
      background-color: transparent;
      width: 1.4em;
      height: 1.4em; }
      .global-header [data-type='@e.contextmenu'].events [data-part="options-group"] [data-part="item"] [data-part="event-title"] [data-type='@e.icon'] svg > *:not([fill="none"]) {
        fill: #fdc02f; }
  .global-header [data-type='@e.contextmenu'].events [data-part="options-group"] [data-part="item"] p {
    margin: 0;
    line-height: 1.6em;
    white-space: normal; }
  .global-header [data-type='@e.contextmenu'].events [data-part="options-group"] [data-part="item"] [data-type='@e.button'] {
    color: #40A8DE;
    background-color: #fff;
    padding: 0.5em calc(0.5em * 2);
    border: 1px solid #40A8DE;
    margin-top: 0.8em; }
  .global-header [data-type='@e.contextmenu'].events [data-part="options-group"] [data-part="item"]:not(:last-child) {
    border-bottom: 1px solid #E4E7E8;
    margin-bottom: 0.5em; }

.global-header [data-type='@e.contextmenu'].events [data-part="options-group"] button {
  color: #40A8DE; }

/**********

	COLOURS : EVS V3

**********/
[data-part="global-nav"] {
  position: absolute;
  top: 1rem;
  left: 0;
  z-index: 3;
  background: #F8F8F8;
  width: 20vw;
  min-width: 30rem;
  -webkit-transform: translateX(-101%);
          transform: translateX(-101%);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  opacity: 0.2;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.2);
  font-size: 1.4rem; }
  [data-part="global-nav"][data-open="true"] {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
    opacity: 1; }
  [data-part="global-nav"] [data-part="header"] {
    display: flex;
    justify-content: space-between; }
    [data-part="global-nav"] [data-part="header"] [data-type="@e.icon"] {
      width: 1.6em;
      height: 1.6em; }
    [data-part="global-nav"] [data-part="header"] [data-part="version"] {
      text-align: right;
      font-size: 1.1rem; }
  [data-part="global-nav"] [data-part="section"] [data-type='@e.link'] [data-type="@e.icon"] {
    margin-right: 0.5em; }
  [data-part="global-nav"] [data-part="section"] [data-type='@e.link'].--current {
    color: #40A8DE; }

/**********

	COLOURS : EVS V3

**********/
html.-no-scroll,
html.-no-scroll body {
  overflow: hidden;
  position: relative;
  height: 100%; }

body {
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  background: white;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden; }
  body.touch-enabled {
    cursor: pointer; }
    body.touch-enabled * {
      cursor: pointer !important; }

* {
  -webkit-overflow-scrolling: touch; }

*, *:before, *:after {
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important;
  box-sizing: border-box; }

*:focus {
  outline: 0; }

a, button {
  text-decoration: none;
  color: inherit;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none; }
  a[target="_blank"], button[target="_blank"] {
    cursor: alias; }
  a.boxed, button.boxed {
    margin: 0 auto;
    display: block;
    font-size: 1em;
    text-transform: uppercase;
    border: 1px solid currentColor;
    line-height: 1em;
    padding: 0.6em 2em; }

img {
  width: 100%;
  height: auto;
  display: block; }

form, input, textarea, select, button {
  font-size: 1em; }

::-webkit-input-placeholder {
  opacity: 0.5; }

::-moz-placeholder {
  opacity: 0.5; }

:-ms-input-placeholder {
  opacity: 0.5; }

:-moz-placeholder {
  opacity: 0.5; }

.rc-slider-tooltip {
  z-index: 1;
  cursor: pointer; }
  .rc-slider-tooltip .rc-slider-tooltip-arrow {
    bottom: -2px;
    border-top-color: #40A8DE;
    margin-left: -6px;
    border-width: 6px 6px 0; }
    .rc-slider-tooltip .rc-slider-tooltip-arrow:before {
      content: '';
      position: absolute;
      bottom: calc(100% + 6px);
      left: -5px;
      width: 10px;
      height: 6px;
      background: #40A8DE; }
  .rc-slider-tooltip .rc-slider-tooltip-inner {
    padding: 0 0 0.6rem 0;
    min-width: 0;
    height: auto;
    font-size: 1em;
    color: #F8F8F8;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none; }


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/**********

	COLOURS : EVS V3

**********/
html, body {
  color: #0E1D26; }

html {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 10px; }

body {
  font-size: 1.3rem; }

h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote {
  margin: 0 0 0.6em 0;
  line-height: 1.25em; }
  h1.light, h2.light, h3.light, h4.light, h5.light, h6.light, p.light, ul.light, ol.light, blockquote.light {
    font-weight: 300; }
  h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong, p strong, ul strong, ol strong, blockquote strong {
    font-weight: 600; }
  h1 strong.xtra, h2 strong.xtra, h3 strong.xtra, h4 strong.xtra, h5 strong.xtra, h6 strong.xtra, p strong.xtra, ul strong.xtra, ol strong.xtra, blockquote strong.xtra {
    font-weight: 700; }

h1[style*="background: linear-gradient("], h1[style*="background: radial-gradient("], h1[style*="background-image: linear-gradient("], h1[style*="background-image: radial-gradient("], h2[style*="background: linear-gradient("], h2[style*="background: radial-gradient("], h2[style*="background-image: linear-gradient("], h2[style*="background-image: radial-gradient("], h3[style*="background: linear-gradient("], h3[style*="background: radial-gradient("], h3[style*="background-image: linear-gradient("], h3[style*="background-image: radial-gradient("], h4[style*="background: linear-gradient("], h4[style*="background: radial-gradient("], h4[style*="background-image: linear-gradient("], h4[style*="background-image: radial-gradient("], h5[style*="background: linear-gradient("], h5[style*="background: radial-gradient("], h5[style*="background-image: linear-gradient("], h5[style*="background-image: radial-gradient("], h6[style*="background: linear-gradient("], h6[style*="background: radial-gradient("], h6[style*="background-image: linear-gradient("], h6[style*="background-image: radial-gradient("], p[style*="background: linear-gradient("], p[style*="background: radial-gradient("], p[style*="background-image: linear-gradient("], p[style*="background-image: radial-gradient("], a[style*="background: linear-gradient("], a[style*="background: radial-gradient("], a[style*="background-image: linear-gradient("], a[style*="background-image: radial-gradient("], blockquote[style*="background: linear-gradient("], blockquote[style*="background: radial-gradient("], blockquote[style*="background-image: linear-gradient("], blockquote[style*="background-image: radial-gradient("] {
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-fill-color: transparent !important;
  padding-bottom: 0.1em !important; }

p + h1, p + h2, p + h3, p + h4, p + h5, p + h6 {
  margin-top: 1.8em; }

blockquote + h1, blockquote + h2, blockquote + h3, blockquote + h4, blockquote + h5, blockquote + h6 {
  margin-top: 1.8em; }

h1 {
  font-size: 2rem; }

h2 {
  font-size: 1.6rem; }

h3 {
  font-size: 1.4rem; }

h4 {
  font-size: 1.3rem; }

h5 {
  font-size: 1.2rem; }

p, ul, ol, table, a, blockquote, figure, form, .copy {
  font-size: 1.2rem; }
  @media only screen and (max-width: 900px) {
    p, ul, ol, table, a, blockquote, figure, form, .copy {
      font-size: 1.2rem; } }

span, time {
  font-size: inherit; }

p, ul, ol, blockquote {
  margin-bottom: 1em;
  line-height: 1.45em; }
  p a, ul a, ol a, blockquote a {
    color: #40A8DE;
    font-size: inherit;
    font-weight: inherit; }
    p a:hover, ul a:hover, ol a:hover, blockquote a:hover {
      color: #145171; }
  p b, p strong, ul b, ul strong, ol b, ol strong, blockquote b, blockquote strong {
    font-weight: 600; }

figure figcaption {
  font-size: 0.9em;
  color: #606262;
  margin-top: 0.7em; }

