@import '@global.style/_colors.scss';
@import '@global.style/_fonts.scss';
@import '@app.elements/icon/mixins.scss';
@import '@app.elements/panel/mixins.scss';
@import '@app.elements/button/mixins.scss';

.app{
	background-color: $color\light-grey;
	min-height: 100vh;


	// child z-indexing
	>._mainnav{ z-index: 3 }


	/*
		elements
	*/

	>.global-header{
		color: $color\light;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 2;
	}
	
	[data-type='@e.breadcrumbs']{
		[data-type='@e.icon']{ @include icon\size(1em) }
		
		[data-part="crumb"]{
			transition: opacity 0.4s;
			&:not(:last-child){
				opacity: 0.5;
			}		
		}
	}

	[data-type='@c.map']
	[data-type='@e.panel']{
		font-size: $font\size\xsmall
	}

	[data-type='@e.contextmenu']{
		[data-part="cta"] { color: $color\blue; }
	}

	[data-type="@e.panel"]{
		@include panel\color($color\dark-grey);
	}
	


	













	.\@e.-panel{
		background: $color\light-grey;
	}

	.\@e.-button{
		.\@e.-icon{
			@include icon\size(1.5em)
		}
	}

	.\@e.-date{
		font-size: calc(#{$font\size\small} * 0.9);
		.date{
			font-weight: 600;
			font-size: $font\size\small;
		}
		.time{
			
		}
	}



	// global threshold colours
	[data-threshold-color]{
		&[data-threshold-color="1"]{ background: $color\threshold\low !important }
		&[data-threshold-color="2"]{ background: $color\threshold\mid !important }
		&[data-threshold-color="3"]{ background: $color\threshold\high !important }
		&[data-threshold-color="4"]{ background: $color\threshold\extreme !important }
		&[data-threshold-color="5"]{ background: $color\threshold\breach !important }
	}


	.leaflet-container .leaflet-tile-pane img {
	    transform: scale(1.006);
	}

	// highcharts
	.highcharts-container {
		.highcharts-series{

			path.highcharts-graph{
				stroke-width: 1 !important;
				transition: stroke 1s;
			}
			
			&.highcharts-series-hover path.highcharts-graph{
				stroke: $color\blue !important;
				stroke-dasharray: 0 !important;
			}
		}

		.highcharts-plot-line,
		.highcharts-plot-line-label {
			opacity: 0.3;
		}
	}
}