@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700);
@import './_mixins';
@import './_colors';
@import './_fonts';

html,body{
	color: $color\dark-grey;
}

html{
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 400;
	font-size: 10px;

	// responisve 
	//@media only screen and (max-width: 1180px) {font-size: 9.5px}
	//@media only screen and (max-width: 1020px) {font-size: 9px}
	//@media only screen and (max-width: 900px) {font-size: 9px}
	//@media only screen and (max-width: 700px) {font-size: 8.5px} // mobile/tablet BP
}

body{
	font-size: $font\size\medium;
}

h1,h2,h3,h4,h5,h6,p,ul,ol,blockquote{
	margin: 0 0 0.6em 0;
	line-height: 1.25em;
	

	&.light{ font-weight: 300 }
	strong{ font-weight: 600 }
	strong.xtra{ font-weight: 700 }
}

// text gradients webkit hack
h1,h2,h3,h4,h5,h6,p,a,blockquote{
	&[style*="background: linear-gradient("],
	&[style*="background: radial-gradient("],
	&[style*="background-image: linear-gradient("],
	&[style*="background-image: radial-gradient("]{
		-webkit-background-clip: text !important;
		background-clip: text !important;
		-webkit-text-fill-color: transparent !important;
		text-fill-color: transparent !important;
		padding-bottom: 0.1em !important;
	}
}

// if heading follows a paragraph, add more padding
h1,h2,h3,h4,h5,h6{
	p + & { margin-top: 1.8em}
	blockquote + & { margin-top: 1.8em}
}

h1{ font-size: $font\size\xxlarge }
h2{ font-size: $font\size\xlarge }
h3{ font-size: $font\size\large }
h4{ font-size: $font\size\medium }
h5{ font-size: $font\size\small }
p,ul,ol,table,a,blockquote,figure,form,.copy{ 
	font-size: $font\size\small;
	@media only screen and (max-width: 900px) {font-size: $font\size\small}
}
span,time{
	font-size: inherit;
}
p,ul,ol,blockquote{
	margin-bottom: 1em;
	line-height: 1.45em;
	a{
		color: $color\blue;
		font-size: inherit;
		font-weight: inherit;
		&:hover{
			color: darken($color\blue, 30%);
		}
	}
	b,strong{ font-weight: 600 }
}

figure figcaption{
	font-size: 0.9em;
	color: $color\grey;
	margin-top: 0.7em;
}