@import './_colors';

html{
	&.-no-scroll,
	&.-no-scroll body{
		overflow:hidden;
		position: relative;
    	height: 100%;
	}
}

body {
	width: 100%;
	height: 100%;
	-webkit-text-size-adjust: 100%; //fix for iOS
	background: white;
	margin: 0;
	padding: 0;
	position: relative;
	overflow-x: hidden;

	&.touch-enabled{
		cursor: pointer;
		*{ cursor: pointer !important; }
	}
}

* {
    -webkit-overflow-scrolling: touch
}

*, *:before, *:after {
	font-smooth: always;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//-webkit-backface-visibility: hidden;
	text-rendering: optimizeLegibility !important;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*:focus {
		outline: 0;
}


a,button{
	text-decoration: none;
	color: inherit;
	transition: color 0.15s;
	cursor: pointer;
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	background: transparent;
	font: inherit;
	line-height: normal;
	
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	-webkit-appearance: none;

	&[target="_blank"]{
		cursor: alias;
	}

	&.boxed{
		margin: 0 auto;
		display: block;
		font-size: 1em;
		text-transform: uppercase;
		border: 1px solid currentColor;
		line-height: 1em;
		padding: 0.6em 2em;
	}
}

img{
	width: 100%;
	height: auto;
	display: block;
}

form, input, textarea, select, button{
	font-size: 1em;
}



::-webkit-input-placeholder {opacity: 0.5}
::-moz-placeholder {opacity: 0.5}
:-ms-input-placeholder {opacity: 0.5}
:-moz-placeholder {opacity: 0.5}


.rc-slider-tooltip{
	z-index: 1;
	cursor: pointer;
	
	.rc-slider-tooltip-arrow{
		bottom: -2px;
		border-top-color: $color\blue;
	    margin-left: -6px;
	    border-width: 6px 6px 0;
	
		&:before{
			content: '';
			position: absolute;
			bottom: calc(100% + 6px);
			left: -5px;
			width: 10px;
			height: 6px;
			background: $color\blue;
		}
	}

	.rc-slider-tooltip-inner {
	    padding: 0 0 0.6rem 0;
	    min-width: 0;
	    height: auto;
	    font-size: 1em;
	    color: $color\light;
	    text-align: center;
	    text-decoration: none;
	    background-color: transparent;
	    border-radius: 0;
	    box-shadow: none;
	}
}