@import '@global.style/_mixins.scss';

// clockwise starting at top-left (position 1)
// 1 : top left;
// 2 : top center;
// 3 : top right;
// 4 : left middle;
// 5 : right middle;
// 6 : bottom left;
// 7 : bottom center;
// 8 : bottom right;
@mixin contextmenu\position($position:2) {
	
	;[data-part='options']{

		left: unset;
		right: unset;
		top: unset;
		bottom: unset;
		transform: none;
		
		[data-part='pointer'] {
			height: auto;
			width: auto;
			svg { 
				right: unset; 
				left: unset;
				transform: none;
			}
		}
		
		// top
		@if $position == 1 or $position == 2 or $position == 3 {
			flex-direction: column-reverse;
			bottom: 100%;
			[data-part='pointer']{
				height: 0.6em;
			}
		} 
		// middle
		@elseif $position == 4 or $position == 5 {
			top: 50%;
			transform: translateY(-50%);
			[data-part='pointer']{
				width: 0.6em;
			}
		} 
		// bottom
		@elseif $position == 6 or $position == 7 or $position == 8 {
			flex-direction: column;
			top: 100%;
			[data-part='pointer'] {
				height: 0.6em;
				svg { 
					@if $position == 7 {
						transform: rotate(180deg) translateX(50%);
					}@else{
						transform: rotate(180deg);
					}
				}
			}
			
		}

		@if $position == 1 or $position == 6 {
			right: calc(100% - 2em);
			[data-part='pointer'] svg { right: 0.5em }
		} @elseif $position == 2 or $position == 7 {
			left: 50%;
			transform: translateX(-50%);
			[data-part='pointer'] svg { 
				left: 50%;
				@if $position == 7 {
					transform: rotate(180deg) translateX(50%);
				}@else{
					transform: translateX(-50%);
				}
			}
		} @elseif $position == 3 or $position == 8 {
			left: calc(100% - 2em);
			[data-part='pointer'] svg { left: 0.5em }
		} @elseif $position == 4 {
			right: 100%;
			flex-direction: row-reverse;
			[data-part='pointer'] svg {
				top: 50%;
				transform: rotate(-90deg) translate(25%, -30%);
			}
		} @elseif $position == 5 {
			left: 100%;
			flex-direction: row;
			[data-part='pointer'] svg {
				top: 50%;
				transform: rotate(90deg) translate(-25%, 30%);
			}
		} 
	}
}

@mixin contextmenu\color($color:#FFF) {
	;[data-part='options'] {
		[data-part='pointer'] svg {
			fill : $color;
		}
		[data-part='children']{
			background: $color;
			color: invert($color);

			[data-part='options-group']{

				>[data-part='title']{
					color: rgba(invert($color), 0.7)
				}
			}
		}

		[data-part='options-group']{
			&:not(:last-child){
				border-bottom: 1px solid rgba(invert($color), 0.2);
			}
		}
	}
}


@mixin contextmenu\arrow() {
	[data-part="trigger"]{
		padding-right: 1.2em;
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			right: 0.5em;
			width: 0.45em;
			height: 0.45em;
			//background: red;
			transform: translateY(-50%) rotateZ(-45deg);
			border-right: 1px solid white;
			border-bottom: 1px solid white;
			opacity: 0.5;
			transition: all 0.2s;
		}

		&:hover:after{
			opacity: 1;
			right: 0.3em;
		};
	}
}