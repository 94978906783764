@mixin nav\size($size:1em){
	font-size: $size;

	[data-part="header"]{
		margin-bottom: $size;
		padding: $size/2 $size*2;
	}

	[data-part="section"]{
		padding: $size $size;

		>[data-part='title']{
			padding: 0 $size*.5;
			margin-bottom: $size*.8
		}

		[data-part="item"]{
			padding: $size*.5
		}

		[data-part='controls']{
			padding: 0 $size*.5;
			margin-top: $size*.8
		}

		&:not(:last-child){
			padding-bottom: $size
		};
	}
}

@mixin nav\color($color:#444){

	background: $color;
	
	[data-part="header"]{ background: lighten($color, 5%) }

	[data-part="section"]{
		>[data-part='title']{ color: rgba(invert($color), 0.6) }
		&:not(:last-child){ border-bottom-color: rgba(invert($color), 0.1) }
	}
}