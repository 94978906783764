@mixin icon\color($icon : currentColor, $background : transparent){
	background-color: $background; 
	svg > *:not([fill="none"]) {
		fill : $icon;
	}
}

@mixin icon\rotate($deg : 0){
	transform: rotate(#{$deg}deg);
}

@mixin icon\size($size){
	width: $size;
	height: $size;
}

@mixin icon\padding($padding : 0){
	svg{
		padding: $padding;
	}
}