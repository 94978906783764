@import '@global.style/_colors.scss';
@import '@global.style/_fonts.scss';
@import '@app.elements/icon/mixins.scss';

[data-part="global-nav"]{
	position: absolute;
	top: 1rem;
	left: 0;
	z-index: 3;
	background: $color\light;
	width: 20vw;
	min-width: 30rem;
	transform: translateX(-101%);
	transition: all 0.2s ease-out;
	opacity: 0.2;
	box-shadow: 0 0 3rem rgba(black, 0.2);
	font-size: $font\size\large;

	&[data-open="true"]{
		transform: translateX(1rem);
		opacity: 1;
	}

	[data-part="header"]{
		display: flex;
		justify-content: space-between;

		[data-type="@e.icon"]{
			@include icon\size(1.6em);
		}

		[data-part="version"]{
			text-align: right;
			font-size: $font\size\xsmall;
		}
	}

	[data-part="section"]{
		[data-type='@e.link']{
			[data-type="@e.icon"]{
				margin-right: 0.5em
			}
			&.--current{ color: $color\blue }
		}
	}

	
}