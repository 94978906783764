@mixin toggle\color($off: #555, $on: #888){
	;&[data-active="false"]{
		color: $off;
		[data-part='switch']{
			background-color: $off;
			[data-part='indicator']{ background: darken($off, 15%) }
		}
	}

	;&[data-active="true"]{
		color: lighten($on, 10%);
		[data-part='switch']{
			background-color: lighten($on, 30%);
			[data-part='indicator']{ background: $on }
		}
	}
	
};

@mixin toggle\size($size){
	;[data-part='switch']{
		border-radius: $size/2;
		width: $size * 1.4;
		height: $size * 0.6;

		[data-part='indicator']{
			width: $size;
			height: $size;
			top: -$size * 0.2;
			left: -$size * 0.2;
		}
	}

	&[data-active="true"]>[data-part='switch']>[data-part='indicator']{ left: 50% }
}