@import '@global.style/_colors.scss';
@import '@global.style/_fonts.scss';
@import '@app.elements/icon/mixins.scss';
@import '@app.elements/dot/mixins.scss';
@import '@app.elements/button/mixins.scss';
@import '@app.elements/contextmenu/mixins.scss';

.global-header{
	.title{
		margin: 0 auto;
		text-indent: -9999px;
		background-image: url('~@global.images/evs-logo-colour-light.svg');
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		width: 14rem;
		height: 3.1rem;
	}
	
	[data-type='@e.icon']{
		@include icon\size(2em);
	}


	[data-type='@e.breadcrumbs']{
		margin-left: 1em
	}

	[data-type='@e.contextmenu']{
		&.events{
			@include contextmenu\position(6);

			[data-part="trigger"]{
				[data-type='@e.icon']{
					@include icon\size(1.6em);
					@include icon\color($color\sunglow)
				}

				[data-type='@e.dot']{
					position: absolute;
					top: 0;
					left: 100%;
					transform: translate(-20%, -30%);
					@include dot\color($color:$color\sunglow, $text: $color\dark)
				}
			}

			[data-part="options"]{
				width: 50rem;
			}

			[data-part="options-group"]{
			   
		

				>*{
					display: block;
					margin: 0;
				}

				[data-part="item"]{
					padding-bottom: 1em;
					flex-direction: column;
					align-items: flex-start;
					
					[data-part="event-title"]{
						display: block;
						font-size: $font\size\large;
						font-weight: 600;
						margin-bottom: 0.4em;
						display: flex;
						align-items: center;
						
						[data-type='@e.icon']{ 
							margin-right: 0.3em;
							@include icon\color($color\sunglow);
							@include icon\size(1.4em);
						}

						
					}

					p{
						margin: 0;
						line-height: 1.6em;
						 white-space: normal;
						
					}

					[data-type='@e.button']{ 
						@include button\color($text: $color\blue);
						@include button\padding(0.5em);
						border: 1px solid $color\blue;
						margin-top: 0.8em;
					}

					&:not(:last-child){
						border-bottom: 1px solid $color\light-grey;
						margin-bottom: 0.5em;
					};

					&:not([data-current="true"]){
						//opacity: 0.6;
					}
				}

				button{
					color: $color\blue;
				}

			}
		}
	}
}
