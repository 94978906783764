@mixin hint\color($background: #eee, $title: #333, $text: #333){;
	[data-part="popup"]{
		background: $background;
		color: $text;

		&:before{
			border-top-color: $background;
		}

		[data-part="popup-title"]{
			color: $title;
		}
	}
}