@import '@global.style/_colors.scss';

[data-type='@c.weatherforecast'] ._weather-chart{

	display: table;
	border-collapse: collapse;
	width: 100%;
	position: relative;

	._row {
		display: table-row;
		position: relative;
		border: 1px solid rgba($color\mid-grey, 0.3);
		
		>*{
			text-align: center;
			display: table-cell;
			padding: 0.5em 0.1em;

			&:nth-child(8n + 2),
			&:nth-child(8n + 3),
			&:nth-child(8n + 4),
			&:nth-child(8n + 5){
				background: rgba($color\light-grey, 0.1);
			}
		}

		._row-name{
			text-align: right;
			font-weight: 400;
			padding: 0.5em;
		}
		
		&.--temp{
			
		}
	}
}