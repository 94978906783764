/**********

	COLOURS : EVS V3

**********/


// --- base -------------------------

	// base greyscale
	$color\white : #FFFFFF;
	$color\alabaster : #F8F8F8;
	$color\porcelain : #E4E7E8;
	$color\silver : #BDBDBD;
	$color\oslo : #95989A;
	$color\corduroy : #606262;
	$color\mako : #3B4045;
	$color\shark : rgb(14, 29, 38);
	$color\bunker : rgb(16,27,31);
	$color\cod : #191919;
	$color\firefly : #0E1D26;
	$color\aztec : #0B1319;
	$color\black : #000000;

	// base colors
	$color\picton : #40A8DE;
	$color\fire : #B92D00;
	$color\pastel : #71D891;

	//secondary colors
	$color\monalisa : #FF9494;
	$color\conifer : #adda48;
	$color\gorse : #FEE84E;
	$color\sunglow : #fdc02f;
	$color\amaranth : #e62465;
	$color\orchid : #E057CD;

// --- brand ------------------------

	// greyscale definitions
	$color\light : $color\alabaster;
	$color\dark : $color\aztec;
	$color\dark-grey : $color\firefly;
	$color\grey : $color\corduroy;
	$color\mid-grey : $color\oslo;
	$color\light-grey : $color\porcelain;
	

	// primary definitions
	$color\blue : $color\picton;
	$color\red : $color\fire;
	$color\green : $color\pastel;
	$color\yellow : $color\sunglow;

	// secondary definitions
	// --- todo ---

	// status definitions
	$color\status\success : $color\pastel;
	$color\status\error : $color\fire;
	$color\status\neutral : $color\picton;

// --- thresholds ------------------------
	$color\threshold\low : $color\conifer;
	$color\threshold\mid : $color\gorse;
	$color\threshold\high : $color\sunglow;
	$color\threshold\extreme : $color\amaranth;
	$color\threshold\breach : $color\orchid;